import React from "react";
import PropTypes from "prop-types";
import Markdown from "./Markdown";

const Chapter = ({ trainingComponent }) => (
  <h3 className="rl-chapter font-bold text-xl">
    <span className="rl-chapter-no" />
    <Markdown source={trainingComponent.data.text} />
  </h3>
);

Chapter.propTypes = {
  trainingComponent: PropTypes.object,
};

export default Chapter;
