import React from "react";
import PropTypes from "prop-types";
import Markdown from "react-markdown/with-html";

const Table = ({ trainingComponent }) => {
  let source = trainingComponent.data.text || "";
  // prevent </br> tags from breaking the rendering
  source = source.replace(/\<\/\s?br>/g, "<br/>");
  return <Markdown source={source} escapeHtml={false} />;
};

Table.propTypes = {
  trainingComponent: PropTypes.object,
};

export default Table;
