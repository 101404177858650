import xhrPost from "./xhrPost";
import JsRoutes from "./routes";

const trackVideo = (videoAttrs) => {
  xhrPost(JsRoutes.videos_path(), {
    data: {
      video: {
        training_id: videoAttrs.training_id,
        video_id: videoAttrs.camera_tag_id,
        cameratag_uuid: videoAttrs.camera_tag_uuid,
        url: videoAttrs.url,
        status: "published",
        medias: videoAttrs.medias,
        meta: videoAttrs.meta,
      },
    },
    error: (e) => {
      window.alert(
        "Video could not be saved. Please try again later or contact us for help."
      );
      console.log("Video could not be saved: ", e);
    },
  });
};

export default trackVideo;
