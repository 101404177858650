import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import practices from "../../../stores/practices";
import Share from "./Share";
import { action, observable } from "mobx";

const TOOLTIPS = {
  video: "Complete all the video recordings to enable sharing.",
  planning: "Complete the practices to enable this feature.",
  videoComplete: "Well done. You’re ready to share! Click the button below.",
  planningComplete: "Well done. You’re ready to share! Click the button below.",
};
const LABELS = {
  video: "Share Final Practice",
  planning: "Email",
};

@observer
class Closing extends Component {
  @observable sharing = false;

  @action.bound
  handleRequestClose() {
    this.sharing = false;
  }

  @action.bound
  handleShare() {
    this.sharing = true;
  }

  render() {
    const component = this.props.trainingComponent;
    const closingType = component.data.closingType;
    const active = practices.allDone();
    const shareButton = this.props.config.user ? (
      <button
        ref={(e) => (this.shareBtn = e)}
        className="button-primary rl-closing"
        disabled={!active}
        title={TOOLTIPS[closingType]}
        onClick={this.handleShare}
      >
        {component.data.buttonLabel || LABELS[closingType]}
      </button>
    ) : (
      <button className="button-primary rl-closing auth-control-signup">
        Share
      </button>
    );
    return (
      <Fragment>
        <div className="mt-2 text-center">
          {active ? (
            <p className="text-sm font-bold my-2">
              {TOOLTIPS[`${closingType}Complete`]}
            </p>
          ) : (
            <p className="text-sm font-bold my-2">{TOOLTIPS[closingType]}</p>
          )}
          {shareButton}
        </div>
        <Share
          isOpen={this.sharing}
          onRequestClose={this.handleRequestClose}
          user={this.props.config.user}
          closingType={closingType}
          training={this.props.training}
          trainingComponent={component}
        />
      </Fragment>
    );
  }
}

Closing.propTypes = {
  trainingComponent: PropTypes.object,
  config: PropTypes.object,
};

export default Closing;
