import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { MAIL_FORMAT } from "../../../patterns";
import JsRoutes from "../../../util/routes";
import xhrPost from "../../../util/xhrPost";
import EmailApprovalForm from "./EmailApprovalForm";

const AccountForm = ({ value, onSubmit, onCancel, edPrepPrograms = null }) => {
  const { register, handleSubmit, setError, errors } = useForm({
    defaultValues: {
      email: value?.email,
      edPrepProgram: value?.edPrepProgram,
    },
  });
  const [email, setEmail] = useState("");
  const [emailApproved, setEmailApproved] = useState(true);
  const [verified, setVerified] = useState(false);

  const validateEmailRules = () => {
    if (email === "") return;
    checkDomainAllowed();
    checkEmailTaken();
    setVerified(true);
  };

  const checkEmailTaken = () => {
    xhrPost(JsRoutes.ensure_registration_email_path(), {
      data: { email: email },
      error: () => {
        setError("email", "taken", "has already been registered");
      },
    });
  };

  const checkDomainAllowed = () => {
    fetch(JsRoutes.allowed_domain_path({ email: email }))
      .then((r) => r.json())
      .then((response) => {
        setEmailApproved(response.allowed);
      });
  };

  const onApprovalRequestCancel = () => {
    setEmail("");
    setEmailApproved(true);
  };

  const handleSubmitEmailVerificationRequest = (edPrepProgramInfo) => {
    return onSubmit({
      ...edPrepProgramInfo,
      user_email: email,
      emailVerificationRequest: true,
    });
  };

  const submitAccount = (account) => {
    return onSubmit(account);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(submitAccount)} className="w-full">
        <div>
          <div className="flex">
            <div className="w-3/5">
              <h3 className="text-lg leading-none my-6 font-bold">
                Create Account
              </h3>
              <div className="form-inputs white-bg-inputs">
                {edPrepPrograms && (
                  <div className="input">
                    <label>Ed Prep Program</label>
                    <select
                      name="ed_prep_program_id"
                      defaultValue={value?.ed_prep_program_id}
                      ref={register}
                    >
                      <option key="ed_prep_program-empty" value="">
                        Select
                      </option>
                      {edPrepPrograms.map((edPrepProgram) => (
                        <option
                          key={`epp-${edPrepProgram.id}`}
                          value={edPrepProgram.id}
                        >
                          {edPrepProgram.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="input">
                  <label>
                    Enter your Ed Prep Program Email<small>*</small>
                  </label>
                  <input
                    className="w-full"
                    autoFocus="autofocus"
                    autoComplete="email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    placeholder="Your e-mail address"
                    onBlur={validateEmailRules}
                    ref={register({
                      required: "is required",
                      pattern: { value: MAIL_FORMAT, message: "is invalid" },
                    })}
                  />
                </div>
              </div>
            </div>
            <div />
          </div>

          <div className="mt-4">
            <button className="button-form mr-4" onClick={onCancel}>
              Back
            </button>
            <button
              type="submit"
              className="button-primary"
              disabled={!verified || !emailApproved}
            >
              Next
            </button>
          </div>

          <div className="mt-4">
            <p className="mt-2">
              Can't find your Ed Prep Program?{" "}
              <a
                className="link"
                href="mailto: support@texastlaconline.org?subject=Ed Prep Program not found in TLaC online Texas!&body=Please help! I cant find my Ed Prep Prgoram in TLac Online Texas version."
              >
                Click here
              </a>{" "}
              for support.
            </p>
          </div>
        </div>
      </form>
      {emailApproved ? (
        ""
      ) : (
        <EmailApprovalForm
          onSubmit={handleSubmitEmailVerificationRequest}
          onCancel={onApprovalRequestCancel}
        />
      )}
    </React.Fragment>
  );
};

export default AccountForm;
