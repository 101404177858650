import JsRoutes from "../util/routes";
import React, { useEffect, useState } from "react";
import uniqBy from "lodash/uniqBy";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import Modal from "./Modal";
import xhrPost from "../util/xhrPost";

const DragHandle = SortableHandle(() => (
  <span className="cursor-grab">
    <i className="fas fa-bars mr-1"></i>
  </span>
));

const getDateRelToday = (rel) => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + rel);
  return [
    tomorrow.getFullYear(),
    ("0" + (tomorrow.getMonth() + 1)).slice(-2),
    ("0" + tomorrow.getDate()).slice(-2),
  ].join("-");
};

const DueDateModal = ({ isOpen, onClose, onSetDueDate, module }) => {
  const [dueDate, setDueDate] = useState(
    module?.due_date || getDateRelToday(1)
  );
  useEffect(() => setDueDate(module?.due_date || getDateRelToday(1)), [module]);
  const onChangeDueDate = (e) => setDueDate(e.target.value);
  const onClearDueDate = () => {
    onSetDueDate("");
  };
  const onAcceptChange = () => onSetDueDate(dueDate);

  if (!module) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className="px-6 pt-2 pb-4">
        <p className="mb-4">
          Update the due date for the module{" "}
          <strong>{module.training.name}</strong>:
        </p>
        <div className="form-inputs">
          <div className="input">
            <input
              type="date"
              onChange={onChangeDueDate}
              value={dueDate}
              min={getDateRelToday(0)}
            />
          </div>
          <div>
            <button
              type="button"
              className="button-form mr-4"
              onClick={onAcceptChange}
            >
              Update Due Date
            </button>
            <button
              type="button"
              className="button-form-secondary mr-4"
              onClick={onClearDueDate}
            >
              Clear Due Date
            </button>
            <button
              type="button"
              className="button-form-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ModuleRow = SortableElement(
  ({ module, onOpenDueDate, onChangeAssigned }) => {
    const handleSetDueDate = (e) => {
      e.preventDefault();
      onOpenDueDate(module);
    };

    const handleChangeAssigned = (e) =>
      onChangeAssigned(module, e.target.checked);

    let dueDate = null;

    if (module.due_date) {
      const parts = module.due_date.split("-");
      dueDate = new Date(parts[0], parts[1] - 1, parts[2]);
    }

    return (
      <tr className="border-b-2 border-light-grey-01">
        <td className="pl-6 py-4">
          <input
            type="checkbox"
            checked={module.is_assigned}
            onChange={handleChangeAssigned}
          />
        </td>
        <td className="py-4">
          <strong>{module.training.name}</strong>
        </td>
        <td className="py-4">{module.technique.name}</td>
        <td className="py-4">
          <a href="#" onClick={handleSetDueDate} className="link">
            {dueDate?.toLocaleDateString(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
            }) || "Set due date"}
          </a>
        </td>
        <td className="py-4">
          <DragHandle />
        </td>
      </tr>
    );
  }
);

const ModuleList = SortableContainer(
  ({ modules, onOpenDueDate, onChangeAssigned }) => {
    return (
      <table className="w-full">
        <thead>
          <tr className="text-left">
            <th className="font-normal pb-2 pl-6">Assigned</th>
            <th className="font-normal pb-2">Module</th>
            <th className="font-normal pb-2">Technique</th>
            <th className="font-normal pb-2" colSpan="2">
              Due Date
            </th>
          </tr>
        </thead>
        <tbody className="border-t-2 border-light-grey-darker">
          {modules.map(({ module, index }) => (
            <ModuleRow
              module={module}
              index={index}
              key={`module-${index}`}
              onOpenDueDate={onOpenDueDate}
              onChangeAssigned={onChangeAssigned}
            />
          ))}
        </tbody>
      </table>
    );
  }
);

const buildParams = (owner, ownerType) => {
  const urlParams = {};
  if (ownerType == "Team") {
    urlParams.team_id = owner.id;
  } else if (ownerType == "User") {
    urlParams.user_id = owner.id;
  }
  return urlParams;
};

const ModuleAssignments = ({ owner, ownerType }) => {
  const [modules, setModules] = useState({
    modules: [],
    updatePolledAt: null,
  });
  const [showFamily, setShowFamily] = useState("");
  const [showTechnique, setShowTechnique] = useState("");
  const [showOnlyAssigned, setShowOnlyAssigned] = useState(false);
  const [editingDueDate, setEditingDueDate] = useState(false);
  const [editingModule, setEditingModule] = useState(null);
  const [busy, setBusy] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setBusy(true);
    fetch(JsRoutes.assignment_path(buildParams(owner, ownerType)))
      .then((r) => r.json())
      .then((newModules) => {
        setModules({ modules: newModules, updatePolledAt: null });
        setBusy(false);
      })
      .catch(() => setBusy(false));
  }, [refresh]);

  useEffect(() => {
    if (!modules.updatePolledAt) {
      return;
    }
    setBusy(true);
    xhrPost(JsRoutes.assignment_path(buildParams(owner, ownerType)), {
      data: {
        modules: modules.modules.map((module) => ({
          id: module.training.id,
          due_date: module.due_date,
          is_assigned: module.is_assigned,
        })),
      },
      success: (newModules) => {
        setModules({ modules: newModules, updatePolledAt: null });
        setBusy(false);
      },
      error: () => {
        setBusy(false);
      },
    });
  }, [modules]);

  const reset = () => {
    setBusy(true);
    xhrPost(JsRoutes.reset_assignment_path(buildParams(owner, ownerType)), {
      success: () => {
        setRefresh(!refresh);
      },
      error: () => {
        setRefresh(!refresh);
      },
    });
  };

  const confirmReset = () => {
    if (
      confirm("Please confirm that the modules should be reset to the default.")
    ) {
      reset();
    }
  };

  const families = uniqBy(
    modules.modules.map((module) => module.family),
    (family) => family.id
  );

  const techniques = uniqBy(
    modules.modules.map((module) => module.technique),
    (technique) => technique.id
  );

  const onChangeFamily = (e) => setShowFamily(e.target.value);
  const onChangeTechnique = (e) => setShowTechnique(e.target.value);
  const onChangeShowOnlyAssigned = () => setShowOnlyAssigned(!showOnlyAssigned);
  const onSortStart = ({ node }) => {
    document.body.style.cursor = "grabbing";
    const tds =
      document.getElementsByClassName("dragging-module")[0].childNodes;
    node.childNodes.forEach(
      (node, idx) => (tds[idx].style.width = `${node.offsetWidth}px`)
    );
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setModules({
      modules: arrayMove(modules.modules.slice(), oldIndex, newIndex),
      updatePolledAt: new Date(),
    });
    document.body.style.cursor = "";
  };
  const onOpenDueDate = (module) => {
    setEditingModule(module);
    setEditingDueDate(true);
  };
  const onCloseDueDate = () => setEditingDueDate(false);
  const onSetDueDate = (newDueDate) => {
    setModules({
      modules: modules.modules.map((module) => {
        if (module.training.id != editingModule.training.id) {
          return module;
        }

        return {
          ...module,
          due_date: newDueDate,
          is_assigned: true,
        };
      }),
      updatePolledAt: new Date(),
    });
    onCloseDueDate();
  };
  const onChangeAssigned = (changingModule, isAssigned) => {
    setModules({
      modules: modules.modules.map((module) => {
        if (module.training.id != changingModule.training.id) {
          return module;
        }

        return {
          ...module,
          due_date: isAssigned ? module.due_date : null,
          is_assigned: isAssigned,
        };
      }),
      updatePolledAt: new Date(),
    });
  };

  const shownModules = [];

  for (let i = 0; i < modules.modules.length; i++) {
    const module = modules.modules[i];

    if (showFamily && module.family.id.toString() != showFamily) {
      continue;
    }

    if (showTechnique && module.technique.id.toString() != showTechnique) {
      continue;
    }

    if (showOnlyAssigned && !module.is_assigned) {
      continue;
    }

    shownModules.push({ module: module, index: i });
  }

  if (!modules.modules.length) {
    return null;
  }

  return (
    <div className={busy ? "module-assignments busy" : "module-assignments"}>
      <DueDateModal
        isOpen={editingDueDate}
        module={editingModule}
        onClose={onCloseDueDate}
        onSetDueDate={onSetDueDate}
      />
      <div className="form-inputs white-bg-inputs">
        <div className="input flex-responsive items-center mx-6 pt-6 pb-2">
          <select
            value={showFamily}
            onChange={onChangeFamily}
            className="md:mr-4"
          >
            <option value="" key="family-option-reset">
              All Categories
            </option>
            {families.map((family) => (
              <option value={family.id} key={`family-option-${family.id}`}>
                {family.name}
              </option>
            ))}
          </select>
          <select
            value={showTechnique}
            onChange={onChangeTechnique}
            className="md:mr-4"
          >
            <option value="" key="technique-option-reset">
              All Techniques
            </option>
            {techniques.map((technique) => (
              <option
                value={technique.id}
                key={`technique-option-${technique.id}`}
              >
                {technique.name}
              </option>
            ))}
          </select>
          <div>
            <label htmlFor="show_only_assigned">
              <input
                type="checkbox"
                id="show_only_assigned"
                onChange={onChangeShowOnlyAssigned}
                checked={showOnlyAssigned}
                className="mr-2"
              />
              Show only assigned
            </label>
          </div>
          <div className="flex-grow text-right">
            <button className="button-form-secondary" onClick={confirmReset}>
              Reset to Default
            </button>
          </div>
        </div>
        <ModuleList
          onSortStart={onSortStart}
          onSortEnd={onSortEnd}
          useDragHandle={true}
          helperClass="dragging-module"
          modules={shownModules}
          onOpenDueDate={onOpenDueDate}
          onChangeAssigned={onChangeAssigned}
        />
      </div>
    </div>
  );
};

export default ModuleAssignments;
