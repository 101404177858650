import React, { Component } from "react";
import PropTypes from "prop-types";
import Markdown from "./Markdown";

class TextAndImage extends Component {
  get img() {
    const atts = this.props.previewMode
      ? this.props.trainingComponent.attachmentsSaved
      : this.props.trainingComponent.attachments;
    return atts && atts.length > 0 ? atts[0] : undefined;
  }

  get text() {
    return this.props.trainingComponent.data.text;
  }

  get position() {
    return this.props.trainingComponent.data.position;
  }

  get altText() {
    return this.props.trainingComponent.data.altText;
  }

  imageLeft() {
    return (
      <div className="row">
        <div className="col-md-4">
          <img src={this.img} alt={this.altText} />
        </div>
        <div className="col-md-8">
          <Markdown source={this.text} />
        </div>
      </div>
    );
  }

  imageRight() {
    return (
      <div className="row">
        <div className="col-md-8">
          <Markdown source={this.text} />
        </div>
        <div className="col-md-4">
          <img src={this.img} alt={this.altText} />
        </div>
      </div>
    );
  }

  render() {
    if (this.position === "left") {
      return this.imageLeft();
    } else {
      return this.imageRight();
    }
  }
}

TextAndImage.propTypes = {
  trainingComponent: PropTypes.object,
  previewMode: PropTypes.bool,
};

export default TextAndImage;
