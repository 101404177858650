import React from "react";
import PropTypes from "prop-types";
import Markdown from "./Markdown";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import PracticeRecord from "./PracticeRecord";

@observer
class PracticeWriteRecord extends PracticeRecord {
  @observable mode = "prompt";

  @action.bound
  onSubmit(e) {
    e.preventDefault();
    this.mode = "record";
  }

  render() {
    const data = this.props.trainingComponent.data;
    const id = this.props.trainingComponent.id;
    return (
      <div className="row">
        <div className="col-md-7">
          {this.mode === "prompt" ? (
            <Markdown source={data.text} />
          ) : (
            this.input.value
          )}
        </div>
        <div className="col-md-5">
          {this.mode === "prompt" ? (
            <form
              action={`#PracticeWriteRecord-${id}`}
              className="rl-component-form"
              onSubmit={this.onSubmit}
            >
              <textarea
                ref={(e) => (this.input = e)}
                className="form-control u-margin-bottom"
                style={{ height: "200px" }}
                name={`main_input[${id}]`}
                defaultValue={this.defaultValue}
              />

              <p className="text-right">
                <button type="submit" className="button-form">
                  Next
                </button>
              </p>
            </form>
          ) : (
            this.cameraTag()
          )}
        </div>
      </div>
    );
  }
}

PracticeWriteRecord.propTypes = {
  trainingComponent: PropTypes.object,
};

export default PracticeWriteRecord;
