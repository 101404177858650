import React, { useCallback, useEffect, useState } from "react";
import replaceArrayItem from "../../util/replaceArrayItem";
import { debounce } from "throttle-debounce";
import gaTrackEvent from "../../gaTrackEvent";

const AddManagers = (props) => {
  const [usersLeft, setUsersLeft] = useState(props.usersLeft);
  const [emails, setEmails] = useState([]);
  const [managers, setManagers] = useState([
    { first_name: "", last_name: "", email: "" },
  ]);
  const [error, setError] = useState(null);

  useEffect(() => {
    gaTrackEvent("sign-up", "view-0", "manager");
  }, []);

  useEffect(() => {
    updateUsersLeft();
  }, [managers]);

  const addUser = (e) => {
    e.preventDefault();

    if (props.usersLeft - managers.length == 0) {
      return;
    }

    setManagers([...managers, { first_name: "", last_name: "", email: "" }]);
  };

  const updateUsersLeft = () => {
    const usersTaken = managers.filter((tc) => tc.email && !tc.error).length;
    setUsersLeft(props.usersLeft - usersTaken);
  };

  const updateEmail = useCallback(
    debounce(500, (managers, managerIdx, newEmail) => {
      newEmail = newEmail.replace(/\s/g, "");
      let error = null;
      if (newEmail && !/\S+@\S+\.\S+/.test(newEmail)) {
        error = `${newEmail} doesn't look like a valid e-mail address.`;
        newEmail = "";
      }

      if (newEmail && managers.find((t) => t.email == newEmail)) {
        error = `${newEmail} has already been added to the list.`;
      }

      if (usersLeft == 0) {
        error = "There are no users left.";
      }

      setManagers(
        replaceArrayItem(managers, managerIdx, (manager) => ({
          ...manager,
          email: newEmail,
          error,
        }))
      );

      updateUsersLeft();
    }),
    []
  );

  const validManagers = managers.filter((tc) => tc.email && !tc.error);

  const onChangeFirstName = (e) => {
    const managerIdx = parseInt(e.target.getAttribute("data-manager-idx"));
    setManagers(
      replaceArrayItem(managers, managerIdx, (manager) => ({
        ...manager,
        first_name: e.target.value,
      }))
    );
  };

  const onChangeLastName = (e) => {
    const managerIdx = parseInt(e.target.getAttribute("data-manager-idx"));
    setManagers(
      replaceArrayItem(managers, managerIdx, (manager) => ({
        ...manager,
        last_name: e.target.value,
      }))
    );
  };

  const onChangeEmail = (e) => {
    const managerIdx = parseInt(e.target.getAttribute("data-manager-idx"));
    updateEmail(managers, managerIdx, e.target.value);
  };

  const submit = () => {
    gaTrackEvent("sign-up", "add-manager-1", "manager");
  };

  const skip = () => {
    gaTrackEvent("sign-up", "add-manager-1-skip", "manager");
  };

  return (
    <div>
      <div className="mt-6 bg-light-grey-02 p-4 mb-6 rounded-md">
        <p className="font-bold mb-2">
          Let's start setting up your group! To begin, please add at least one
          group manager.
        </p>
        <p>
          A Group Manager is the leader who has the responsibility to support
          and track progress of teachers that are part of the subscription
          (typically a principal or district coordinator). We will be reaching
          out to the group manager to support your organization's implementation
          efforts.
        </p>
        <button className="link mt-2" type="submit" onClick={skip}>
          I'm the only manager
        </button>
      </div>
      <h2 className="text-base font-bold mt-4">
        Invite one or more Group Managers
      </h2>
      <div className="form-inputs white-bg-inputs mt-6">
        {managers.map((manager, idx) => (
          <div>
            <div className="flex" key={`manager-${idx}`}>
              <div className="input w-1/3 md:mr-4">
                <input
                  data-manager-idx={idx}
                  type="text"
                  className="w-full"
                  placeholder="First name"
                  onChange={onChangeFirstName}
                />
              </div>
              <div className="input w-1/3 md:mr-4">
                <input
                  data-manager-idx={idx}
                  type="text"
                  className="w-full"
                  placeholder="Last name"
                  onChange={onChangeLastName}
                />
              </div>
              <div className="input w-1/3 md:mr-4">
                <input
                  data-manager-idx={idx}
                  type="text"
                  className="w-full"
                  placeholder="Email address"
                  onChange={onChangeEmail}
                />
              </div>
            </div>
            {manager.error ? (
              <div className="error-message mb-4">{manager.error}</div>
            ) : null}
          </div>
        ))}
        <div className="hidden">
          {validManagers.map((manager, idx) => (
            <div key={`manager-input-${idx}`}>
              <input
                type="hidden"
                name="managers[][first_name]"
                value={manager.first_name}
              />
              <input
                type="hidden"
                name="managers[][last_name]"
                value={manager.last_name}
              />
              <input
                type="hidden"
                name="managers[][email]"
                value={manager.email}
              />
            </div>
          ))}
        </div>
        {error ? <p className="error-message">{error}</p> : null}
        <button className="link my-4" onClick={addUser} type="button">
          Invite another user
        </button>
        <p>
          <strong>{usersLeft}</strong> users left
        </p>
        <div className="flex mt-4">
          <button
            className="button-form"
            type="submit"
            disabled={validManagers.length == 0}
            onClick={submit}
          >
            Invite Managers
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddManagers;
