import React, { useEffect, useState } from "react";
import FieldError from "../FieldError";
import { PWD_FORMAT } from "../../patterns";
import JsRoutes from "../../util/routes";
import getMetaValue from "../../util/getMetaValue";
import gaTrackEvent from "../../gaTrackEvent";

const OnboardUser = ({ invitationToken, role, user, texas }) => {
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [nameError, setNameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordConf, setPasswordConf] = useState("");
  const [termsAgreement, setTermsAgreement] = useState(false);
  const [dataConsent, setDataConsent] = useState(false);

  useEffect(() => {
    gaTrackEvent("sign-up", "welcome-1-view", role);
  }, []);

  useEffect(() => {
    if (firstName?.trim() && lastName?.trim()) {
      setNameError(null);
    }
  }, [firstName, lastName]);

  useEffect(() => {
    if (password && !PWD_FORMAT.test(password)) {
      setPasswordError(
        "Make sure the password is at least 6 characters long and has a mix of upper and lower case letters, numbers and symbols."
      );
    } else if (passwordConf && password != passwordConf) {
      setPasswordError(
        "Make sure the password confirmation matches the password."
      );
    } else {
      setPasswordError(null);
    }
  }, [password, passwordConf]);

  const onChangeInput = (setter, isCheckbox) => (e) =>
    setter(isCheckbox ? e.target.checked : e.target.value);

  const onChangePassword = onChangeInput(setPassword);
  const onChangePasswordConf = onChangeInput(setPasswordConf);
  const onChangeTermsAgreement = onChangeInput(setTermsAgreement, true);
  const onChangeDataConsent = onChangeInput(setDataConsent, true);
  const onChangeFirstName = onChangeInput(setFirstName);
  const onChangeLastName = onChangeInput(setLastName);

  const onSubmitForm = (e) => {
    if (passwordError) {
      e.preventDefault();
      return;
    }

    if (password && !passwordConf) {
      setPasswordError(
        "Make sure the password confirmation matches the password."
      );
      e.preventDefault();
      return;
    }

    if (!firstName?.trim() || !lastName?.trim()) {
      setNameError("First and last name are required.");
      e.preventDefault();
      return;
    }

    if (!dataConsent || !termsAgreement) {
      setError(
        "To continue, you must agree to our terms and conditions and consent to have your data stored in the USA."
      );
      e.preventDefault();
      return;
    }

    gaTrackEvent("sign-up", "welcome-2-sign-up", role);
  };

  const formUrl = invitationToken
    ? JsRoutes.user_invitation_path()
    : JsRoutes.onboard_update_profile_path();

  return (
    <form
      className="form-inputs white-bg-inputs"
      onSubmit={onSubmitForm}
      method="POST"
      action={formUrl}
    >
      <input
        type="hidden"
        name="authenticity_token"
        value={getMetaValue("csrf-token")}
      />
      {invitationToken ? (
        <div className="hidden">
          <input type="hidden" name="_method" value="put" />
          <input
            type="hidden"
            name="user[invitation_token]"
            value={invitationToken}
          />
        </div>
      ) : null}
      <div className="mt-6">
        <div className="inline inline-input concise mr-6">
          <label className="mr-2" htmlFor="first_name">
            First name
          </label>
          <input
            id="first_name"
            name="user[first_name]"
            type="text"
            onChange={onChangeFirstName}
            value={firstName}
          />
        </div>
        <div className="inline inline-input concise">
          <label className="mr-2" htmlFor="last_name">
            Last name
          </label>
          <input
            id="last_name"
            name="user[last_name]"
            type="text"
            onChange={onChangeLastName}
            value={lastName}
          />
        </div>
        {nameError ? <FieldError>{nameError}</FieldError> : null}
      </div>
      <div className="input mt-6">
        <label htmlFor="">How many years have you been teaching?</label>
        <div className="inline inline-input concise mr-20">
          <input
            id="years_of_teaching-0-1"
            name="user[years_of_teaching]"
            value="0-1"
            type="radio"
            className="mr-2"
          />
          <label htmlFor="years_of_teaching-0-1">0-1</label>
        </div>
        <div className="inline inline-input concise mr-20">
          <input
            id="years_of_teaching-2-5"
            name="user[years_of_teaching]"
            value="2-5"
            type="radio"
            className="mr-2"
          />
          <label htmlFor="years_of_teaching-2-5">2-5</label>
        </div>
        <div className="inline inline-input concise">
          <input
            id="years_of_teaching-6"
            name="user[years_of_teaching]"
            value="6+"
            type="radio"
            className="mr-2"
          />
          <label htmlFor="years_of_teaching-6">6+</label>
        </div>
      </div>
      <div className="input mt-6">
        <label htmlFor="">What grade levels do you teach?</label>
        <div className="inline inline-input concise mr-20">
          <input
            id="grade_levels_taught-K-5"
            name="user[grade_levels_taught]"
            value="K-5"
            type="radio"
            className="mr-2"
          />
          <label htmlFor="grade_levels_taught-K-5">K-5</label>
        </div>
        <div className="inline inline-input concise mr-20">
          <input
            id="grade_levels_taught-6-8"
            name="user[grade_levels_taught]"
            value="6-8"
            type="radio"
            className="mr-2"
          />
          <label htmlFor="grade_levels_taught-6-8">6-8</label>
        </div>
        <div className="inline inline-input concise">
          <input
            id="grade_levels_taught-9-12"
            name="user[grade_levels_taught]"
            value="9-12"
            type="radio"
            className="mr-2"
          />
          <label htmlFor="grade_levels_taught-9-12">9-12</label>
        </div>
      </div>
      <div className="input mt-6">
        <label htmlFor="">What subject areas do you teach?</label>
        <div className="flex-responsive">
          <div className="mr-20">
            <div className="inline-input concise">
              <input
                name="user[subject_areas][]"
                value="math"
                id="subject_areas-math"
                type="checkbox"
                className="mr-2"
              />
              <label htmlFor="subject_areas-math">Math</label>
            </div>
            <div className="inline-input concise">
              <input
                name="user[subject_areas][]"
                value="ela"
                id="subject_areas-ela"
                type="checkbox"
                className="mr-2"
              />
              <label htmlFor="subject_areas-ela">ELA</label>
            </div>
            <div className="inline-input concise">
              <input
                name="user[subject_areas][]"
                value="social_studies"
                id="subject_areas-social_studies"
                type="checkbox"
                className="mr-2"
              />
              <label htmlFor="subject_areas-social_studies">
                Social Sciences
              </label>
            </div>
            <div className="inline-input concise">
              <input
                name="user[subject_areas][]"
                value="fitness_pe"
                id="subject_areas-fitness_pe"
                type="checkbox"
                className="mr-2"
              />
              <label htmlFor="subject_areas-fitness_pe">Fitness/PE</label>
            </div>
            <div className="inline-input concise">
              <input
                name="user[subject_areas][]"
                value="special_education"
                id="subject_areas-special_education"
                type="checkbox"
                className="mr-2"
              />
              <label htmlFor="subject_areas-special_education">
                Special Education
              </label>
            </div>
          </div>
          <div>
            <div className="inline-input concise">
              <input
                name="user[subject_areas][]"
                value="science"
                id="subject_areas-science"
                type="checkbox"
                className="mr-2"
              />
              <label htmlFor="subject_areas-science">Science</label>
            </div>
            <div className="inline-input concise">
              <input
                name="user[subject_areas][]"
                value="world_languages"
                id="subject_areas-world_languages"
                type="checkbox"
                className="mr-2"
              />
              <label htmlFor="subject_areas-world_languages">
                World Languages
              </label>
            </div>
            <div className="inline-input concise">
              <input
                name="user[subject_areas][]"
                value="arts"
                id="subject_areas-arts"
                type="checkbox"
                className="mr-2"
              />
              <label htmlFor="subject_areas-arts">Arts</label>
            </div>
            <div className="inline-input concise">
              <input
                name="user[subject_areas][]"
                id="subject_areas-ell"
                value="ell"
                type="checkbox"
                className="mr-2"
              />
              <label htmlFor="subject_areas-ell">
                English Language Learners
              </label>
            </div>
          </div>
        </div>
      </div>
      {invitationToken ? (
        <div>
          <h3 className="text-lg leading-none my-6 font-bold">
            Set up your password
          </h3>
          <p>
            Your password must be at least 6 characters long and have a mix of
            upper and lower case letters, numbers and symbols.
          </p>
          <div className="input">
            <input
              type="password"
              name="user[password]"
              value={password}
              onChange={onChangePassword}
            />
          </div>
          <div className="input">
            <label htmlFor="">
              Hey, that's awesome! Now do it again just to be sure.
            </label>
            <input
              type="password"
              name="user[password_confirmation]"
              value={passwordConf}
              onChange={onChangePasswordConf}
            />
          </div>
          {passwordError ? <FieldError>{passwordError}</FieldError> : null}
        </div>
      ) : null}
      <div>
        <h3 className="text-lg leading-none my-6 font-bold">Permissions</h3>
        <div className="check_boxes">
          <div className="checkbox">
            <input
              className="mr-2"
              id="terms_agreement"
              type="checkbox"
              checked={termsAgreement}
              onChange={onChangeTermsAgreement}
            />
            <label htmlFor="terms_agreement">
              I agree to the&nbsp;
              <a href="/pages/terms" target="_blank" className="link">
                Terms and Conditions of Use
              </a>
              .
            </label>
          </div>
          <div className="checkbox">
            <input
              className="mr-2"
              id="data_consent"
              type="checkbox"
              checked={dataConsent}
              onChange={onChangeDataConsent}
            />
            <label htmlFor="data_consent">
              I consent to having my data stored on servers located in the
              United States.
            </label>
          </div>
          {texas ? null : (
            <div className="checkbox">
              <input
                className="mr-2"
                id="email_consent"
                type="checkbox"
                name="user[email_consent]"
              />
              <label htmlFor="email_consent">
                Together Education may send me email about other Teach Like a
                Champion products and services that may be of interest to me.
              </label>
            </div>
          )}
        </div>
      </div>
      {error ? <FieldError>{error}</FieldError> : null}
      <div className="mt-8">
        <button type="submit" className="button-primary">
          {invitationToken ? "Sign Up" : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default OnboardUser;
