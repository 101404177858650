import React, { useCallback, useEffect, useState } from "react";
import replaceArrayItem from "../../util/replaceArrayItem";
import { debounce } from "throttle-debounce";
import gaTrackEvent from "../../gaTrackEvent";

const AddCoaches = (props) => {
  const [teamCoaches, setTeamCoaches] = useState([]);

  useEffect(() => {
    setTeamCoaches(
      props.teams.map((team) => ({
        coachEmail: "",
        team,
      }))
    );
  }, [props.teams]);

  const updateCoachEmail = useCallback(
    debounce(1000, (currentTeamCoaches, teamCoachIdx, newEmail) => {
      newEmail = newEmail.replace(/\s/g, "");
      let error = null;
      if (newEmail) {
        if (!/\S+@\S+\.\S+/.test(newEmail)) {
          error = `${newEmail} doesn't look like a valid e-mail address.`;
        } else if (usersLeft == 0) {
          error = "You no longer have any users available in your group.";
        }
      }

      setTeamCoaches(
        replaceArrayItem(currentTeamCoaches, teamCoachIdx, (teamCoach) => ({
          coachEmail: newEmail,
          team: teamCoach.team,
          error,
        }))
      );
    }),
    []
  );

  const validTeamCoaches = teamCoaches.filter(
    (tc) => tc.coachEmail && !tc.error
  );

  const usersLeft = props.usersLeft - validTeamCoaches.length;

  const onChangeCoachEmail = (e) => {
    const teamCoachIdx = parseInt(e.target.getAttribute("data-team-coach-idx"));
    updateCoachEmail(teamCoaches, teamCoachIdx, e.target.value);
  };

  const onChangeCoachTeam = (e) => {
    const teamCoachIdx = parseInt(e.target.getAttribute("data-team-coach-idx"));
    setTeamCoaches(
      replaceArrayItem(teamCoaches, teamCoachIdx, (teamCoach) => ({
        coachEmail: teamCoach.coachEmail,
        team: props.teams.find((t) => t.id == parseInt(e.target.value)),
      }))
    );
  };

  const addCoach = (e) => {
    e.preventDefault();

    if (usersLeft == 0) {
      return;
    }

    setTeamCoaches([...teamCoaches, { coachEmail: "", team: props.teams[0] }]);
  };

  const submit = () => {
    gaTrackEvent("sign-up", "add-coaches-3", "manager");
  };

  const skip = (e) => {
    setTeamCoaches([]);
    gaTrackEvent("sign-up", "add-coaches-3-skip", "manager");
  };

  return (
    <div className="form-inputs white-bg-inputs mt-6">
      {props.teams.length ? (
        <div>
          <p class="my-4">Invite coaches to manage your group's teams</p>
          {teamCoaches.map((teamCoach, idx) => (
            <div key={`tc-${idx}`}>
              <div className="flex">
                <div className="input w-3/4 mr-4">
                  <input
                    data-team-coach-idx={idx}
                    type="text"
                    className="w-full"
                    placeholder="Coach's e-mail address"
                    onChange={onChangeCoachEmail}
                  />
                </div>
                <div className="input w-1/4">
                  <select
                    data-team-coach-idx={idx}
                    value={teamCoach.team.id}
                    className="w-full"
                    onChange={onChangeCoachTeam}
                  >
                    {props.teams.map((team) => (
                      <option key={`team-${idx}-${team.id}`} value={team.id}>
                        {team.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {teamCoach.error ? (
                <div className="error-message mb-4">{teamCoach.error}</div>
              ) : null}
            </div>
          ))}
          <button className="button-link pl-0" onClick={addCoach} type="button">
            Add another coach
          </button>
          <p className="mt-4">
            <strong>{usersLeft}</strong> users left
          </p>
          {validTeamCoaches.length ? (
            <div className="hidden">
              {validTeamCoaches.map((tc, idx) => (
                <div key={`tc-hidden-${idx}`}>
                  <input
                    type="hidden"
                    name="team_coaches[][email]"
                    value={tc.coachEmail}
                  />
                  <input
                    type="hidden"
                    name="team_coaches[][team_id]"
                    value={tc.team.id}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      ) : (
        <p>
          Looks you haven't added any teams yet. If you changed your mind, you
          can add teams and coaches later.
        </p>
      )}

      <div className="flex mt-6">
        <button
          className="button-form"
          type="submit"
          disabled={validTeamCoaches.length == 0}
          onClick={submit}
        >
          Invite Coaches
        </button>
        <button className="button-link" onClick={skip} type="submit">
          Skip this, I'll invite coaches later
        </button>
      </div>
    </div>
  );
};

export default AddCoaches;
