import React, { useEffect, useState } from "react";
import AccountForm from "./AccountForm";
import AccountFormPath2 from "./AccountFormPath2";
import ProfileForm from "./ProfileForm";
import JsRoutes from "../../../util/routes";
import xhrPost from "../../../util/xhrPost";
import FieldError from "../../FieldError";
import parseServerErrors from "../../../util/parseServerErrors";
import gaTrackEvent from "../../../gaTrackEvent";
import AccountTypeForm, { typeOptions } from "./AccountTypeForm";

const stepClass = (isActive) =>
  isActive ? "progress-item-active" : "progress-item";

const TexasSignUp = (props) => {
  const [step, setStep] = useState(1);
  const [account, setAccount] = useState(null);
  const [profile, setProfile] = useState(null);
  const [type, setType] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [
    emailVerificationRequestSent,
    setEmailVerificationRequestSent,
  ] = useState(false);

  useEffect(() => {
    gaTrackEvent("sign-up", "view-0", "primary");
  }, []);

  useEffect(() => {
    if (submitting) handleSubmit();
  }, [submitting]);

  const handleChangeAccount = (newAccount) => {
    gaTrackEvent("sign-up", "account-1", "primary");
    setAccount(newAccount);
    setStep(3);
  };

  const handleChangeAccountPath2 = (newAccount) => {
    gaTrackEvent("sign-up", "account-2", "primary");
    if (newAccount.emailVerificationRequest) {
      handleEmailEdPrepProgramInfo(newAccount);
      return;
    }
    setAccount(newAccount);
    setStep(3);
  };

  const handleChangeProfile = (newProfile) => {
    gaTrackEvent("sign-up", "subscribe-2", "primary");
    setProfile(newProfile);
    setSubmitting(true);
  };

  const handleChangeType = (newType) => {
    gaTrackEvent("sign-up", "type-1", "primary");
    setType(newType);
    setStep(2);
  };

  const handleEmailEdPrepProgramInfo = (edPrepProgramInfo) => {
    xhrPost(JsRoutes.email_approval_request_path(), {
      data: {
        ed_prep_program: {
          fullname: edPrepProgramInfo.fullname,
          ed_prep_program: edPrepProgramInfo.ed_prep_program,
          instructor_name: edPrepProgramInfo.instructor_name,
          instructor_email: edPrepProgramInfo.instructor_email,
          user_email: edPrepProgramInfo.user_email,
        },
      },
      success: () => {
        setEmailVerificationRequestSent(true);
        setSubmitting(false);
      },
      error: (e) => {
        setSubmitting(false);
        const errorMessage =
          "Something went wrong when requesting revision for your email domain. Please try again later, or contact us for help.";
        e.response
          .json()
          .then((data) => {
            setErrors(parseServerErrors(data, errorMessage));
          })
          .catch((e) => {
            setErrors([errorMessage]);
          });
      },
    });
  };

  const handleSubmit = () => {
    xhrPost(JsRoutes.user_registration_path(), {
      data: {
        user: {
          email: account.email,
          password: profile.password,
          password_confirmation: profile.password_confirmation,
          first_name: profile.first_name,
          last_name: profile.last_name,
          school_id: account.school_id,
          district_id: account.district?.value,
          district: account.district?.value,
          ed_prep_program_id: account.ed_prep_program_id,
        },
      },
      success: (_data, response) => {
        const newLocation =
          response.headers.get("Location") || JsRoutes.root_path();
        window.location.href = newLocation;
      },
      error: (e) => {
        setSubmitting(false);
        const errorMessage =
          "Something went wrong when creating your account. Please try again later, or contact us for help.";
        e.response
          .json()
          .then((data) => {
            setErrors(parseServerErrors(data, errorMessage));
          })
          .catch((e) => {
            setErrors([errorMessage]);
          });
      },
    });
  };

  const ThankYou = () => {
    return (
      <div className="px-3-5rem pb-12 text-primary-grey-blue md:w-56rem text-sm">
        <h1 className="leading-none text-primary-grey-blue-02 mb-12">
          Thank You
        </h1>
        <div className="py-6 px-8 bg-white">
          <h3>An email has been sent to Support</h3>
          <p>
            Once we verify your enrollment, we will contact you with directions
            for how to sign-up.
          </p>
        </div>
      </div>
    );
  };

  const stepBack = () => setStep(step - 1);

  const stepClasses = [
    stepClass(step == 1),
    stepClass(step == 2),
    stepClass(step == 3),
  ];

  return emailVerificationRequestSent ? (
    <ThankYou />
  ) : (
    <div className="px-3-5rem pb-12 text-primary-grey-blue md:w-56rem text-sm">
      <h1 className="leading-none text-primary-grey-blue-02 mb-12">Sign Up</h1>
      <div className="py-6 px-8 bg-white">
        <ul id="progressbar" className="flex font-bold">
          <li className={stepClasses[0]}>1. Type</li>
          <li className={stepClasses[1]}>2. Account</li>
          <li className={stepClasses[2]}>3. Profile</li>
        </ul>
        {step === 1 ? (
          <AccountTypeForm onSubmit={handleChangeType} value={type} />
        ) : null}
        {step === 2 && type?.selectedOption === typeOptions.publicSchool ? (
          <AccountForm
            onCancel={stepBack}
            onSubmit={handleChangeAccount}
            value={account}
          />
        ) : step === 2 && type?.selectedOption === typeOptions.edPrepProgram ? (
          <AccountFormPath2
            onCancel={stepBack}
            onSubmit={handleChangeAccountPath2}
            value={account}
            edPrepPrograms={props.edPrepPrograms}
          />
        ) : null}
        {step === 3 ? (
          <ProfileForm
            onSubmit={handleChangeProfile}
            onCancel={stepBack}
            value={profile}
            disabled={submitting}
          />
        ) : null}
        {errors.map((error, idx) => (
          <FieldError key={`error-${idx}`}>{error}</FieldError>
        ))}
      </div>
    </div>
  );
};

export default TexasSignUp;
