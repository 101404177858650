import React, { useCallback, useEffect, useRef, useState } from "react";
import replaceArrayItem from "../util/replaceArrayItem";
import JsRoutes from "../util/routes";
import { debounce } from "throttle-debounce";
import gaTrackEvent from "../gaTrackEvent";

const ROLES = {
  teacher: "Teacher",
  coach: "Coach",
  manager: "Manager",
};

const InviteUsers = (props) => {
  const [teamUsers, setTeamUsers] = useState([]);
  const [importDisabled, setImportDisabled] = useState(true);
  const fileInput = useRef(null);
  const [recentlyPurchasedAccounts, setRecentlyPurchasedAccounts] = useState(0);

  useEffect(() => {
    let purchasedAccountsFromLS = parseInt(
      localStorage.getItem("recentlyPurchasedAccounts")
    );
    setRecentlyPurchasedAccounts(purchasedAccountsFromLS);
    localStorage.removeItem("recentlyPurchasedAccounts");
  }, []);

  useEffect(() => {
    setTeamUsers([
      {
        firstName: "",
        lastName: "",
        email: "",
        team: props.teams.length ? props.teams[0] : null,
        role: "teacher",
      },
    ]);
  }, [props.teams]);

  const updateUserEmail = useCallback(
    debounce(1000, (currentTeamUsers, teamUserIdx, newEmail) => {
      newEmail = newEmail.replace(/\s/g, "");
      let error = null;
      if (newEmail && !/\S+@\S+\.\S+/.test(newEmail)) {
        error = `${newEmail} doesn't look like a valid e-mail address.`;
        newEmail = "";
      }

      if (newEmail && currentTeamUsers.find((t) => t.email == newEmail)) {
        error = `${newEmail} has already been added to the list.`;
      }

      setTeamUsers(
        replaceArrayItem(currentTeamUsers, teamUserIdx, (teamUser) => ({
          ...teamUser,
          email: newEmail,
          error,
        }))
      );
    }),
    []
  );

  const onChangeUserFirstName = (e) => {
    const teamUserIdx = parseInt(e.target.getAttribute("data-team-user-idx"));
    setTeamUsers(
      replaceArrayItem(teamUsers, teamUserIdx, (teamUser) => ({
        ...teamUser,
        firstName: e.target.value,
      }))
    );
  };

  const onChangeUserLastName = (e) => {
    const teamUserIdx = parseInt(e.target.getAttribute("data-team-user-idx"));
    setTeamUsers(
      replaceArrayItem(teamUsers, teamUserIdx, (teamUser) => ({
        ...teamUser,
        lastName: e.target.value,
      }))
    );
  };

  const onChangeUserEmail = (e) => {
    const teamUserIdx = parseInt(e.target.getAttribute("data-team-user-idx"));
    updateUserEmail(teamUsers, teamUserIdx, e.target.value);
  };

  const onChangeUserRole = (e) => {
    const teamUserIdx = parseInt(e.target.getAttribute("data-team-user-idx"));
    const newRole = e.target.value;
    setTeamUsers(
      replaceArrayItem(teamUsers, teamUserIdx, (teamUser) => ({
        ...teamUser,
        role: newRole,
        team: newRole == "manager" ? null : teamUser.team,
      }))
    );
  };

  const onChangeUserTeam = (e) => {
    const teamUserIdx = parseInt(e.target.getAttribute("data-team-user-idx"));
    const newTeam = e.target.value;
    setTeamUsers(
      replaceArrayItem(teamUsers, teamUserIdx, (teamUser) => ({
        ...teamUser,
        team: newTeam
          ? props.teams.find((t) => t.id == parseInt(e.target.value))
          : null,
      }))
    );
  };

  const addUser = (e) => {
    e.preventDefault();

    if (props.usersLeft - validTeamUsers.length == 0) {
      return;
    }

    setTeamUsers([
      ...teamUsers,
      {
        firstName: "",
        lastName: "",
        email: "",
        team: props.teams.length ? props.teams[0] : null,
        role: "teacher",
      },
    ]);
  };

  const removeUser = (idx) => {
    teamUsers.splice(idx, 1);
    setTeamUsers([...teamUsers]);
  };

  const onChangeImport = (e) => {
    const hasFiles = fileInput?.current?.files?.length ? true : false;
    setImportDisabled(!hasFiles);
  };

  const validTeamUsers = teamUsers.filter((tc) => tc.email);
  const usersLeft = props.usersLeft - validTeamUsers.length;

  const submit = () => {
    gaTrackEvent("invite", "invite-teachers", "manager");
  };

  const handleFileBtnClick = (e) => {
    e.preventDefault();
    document.getElementById("users_import").click();
  };

  return (
    <div className="form-inputs white-bg-inputs">
      {recentlyPurchasedAccounts > 0 ? (
        <div className="w-full rounded bg-light-grey-03 p-4 mb-8">
          <span className="font-bold">
            {recentlyPurchasedAccounts} new accounts have been added to your
            group.
          </span>{" "}
          You can add them now by importing from a file or individually by
          email.
        </div>
      ) : null}
      <div>
        <strong>Import users from a file</strong>
        <p className="my-4">
          Upload a CSV file with the users to be imported with{" "}
          <a className="link" href={"/setup_users_sample.csv"}>
            this header and structure
          </a>
          .
        </p>
        <div className="flex">
          <div className="input">
            <button className="button-form mr-4" onClick={handleFileBtnClick}>
              Choose a File
            </button>
            <input
              id="users_import"
              type="file"
              name="users_import"
              accept=".csv"
              className="mr-4"
              ref={fileInput}
              onChange={onChangeImport}
            ></input>
            <button
              className="button-form"
              type="submit"
              disabled={importDisabled}
            >
              Import
            </button>
          </div>
        </div>
      </div>
      <hr className="my-8" />
      <div>
        <strong>Invite users by e-mail</strong>
        <div className="mt-4">
          {teamUsers.map((teamUser, idx) => (
            <div key={`tc-${idx}`}>
              <div className="flex">
                <div className="input w-2/12 md:mr-4">
                  <input
                    data-team-user-idx={idx}
                    type="text"
                    className="w-full"
                    placeholder="First name"
                    onChange={onChangeUserFirstName}
                  />
                </div>
                <div className="input w-2/12 md:mr-4">
                  <input
                    data-team-user-idx={idx}
                    type="text"
                    className="w-full"
                    placeholder="Last name"
                    onChange={onChangeUserLastName}
                  />
                </div>
                <div className="input w-3/12 md:mr-4">
                  <input
                    data-team-user-idx={idx}
                    type="text"
                    className="w-full"
                    placeholder="Email address"
                    onChange={onChangeUserEmail}
                  />
                </div>
                <div className="input w-2/12 md:mr-4">
                  <select
                    data-team-user-idx={idx}
                    value={teamUser.role}
                    className="w-full"
                    onChange={onChangeUserRole}
                  >
                    {Object.keys(ROLES).map((role) => (
                      <option key={`role-${idx}-${role}`} value={role}>
                        {ROLES[role]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input w-2/12 md:mr-4">
                  <select
                    data-team-user-idx={idx}
                    value={teamUser.team ? teamUser.team.id : ""}
                    className="w-full"
                    onChange={onChangeUserTeam}
                    disabled={teamUser.role == "manager"}
                  >
                    <option key="team-none" value=""></option>
                    {props.teams.map((team) => (
                      <option key={`team-${idx}-${team.id}`} value={team.id}>
                        {team.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className="w-1/12 cursor-pointer text-primary-yellow mt-2 md:mr-4"
                  onClick={() => removeUser(idx)}
                >
                  <i className="fas fa-minus-circle"></i>
                </div>
              </div>

              {teamUser.error ? (
                <div className="error-message mb-4">{teamUser.error}</div>
              ) : null}
            </div>
          ))}
        </div>
        <button className="button-link pl-0" onClick={addUser} type="button">
          Invite another user
        </button>
        <p className="mt-4">
          <strong>{usersLeft}</strong> users left
        </p>
        {validTeamUsers.length ? (
          <div className="hidden">
            {validTeamUsers.map((tc, idx) => (
              <div key={`tc-hidden-${idx}`}>
                <input
                  type="hidden"
                  name="team_users[][first_name]"
                  value={tc.firstName}
                />
                <input
                  type="hidden"
                  name="team_users[][last_name]"
                  value={tc.lastName}
                />
                <input
                  type="hidden"
                  name="team_users[][email]"
                  value={tc.email}
                />
                <input
                  type="hidden"
                  name="team_users[][role]"
                  value={tc.role}
                />
                {tc.team ? (
                  <input
                    type="hidden"
                    name="team_users[][team_id]"
                    value={tc.team.id}
                  />
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
      </div>

      <div className="flex mt-6">
        <button
          className="button-form mr-6"
          type="submit"
          disabled={validTeamUsers.length == 0}
          onClick={submit}
        >
          {`Add ${validTeamUsers.length || ""} Users`}
        </button>
        <a className="link mt-2" href={JsRoutes.dashboard_path()}>
          Add users later
        </a>
      </div>
    </div>
  );
};

export default InviteUsers;
