import xhrPost from "./xhrPost";
import JsRoutes from "./routes";

const trackTrainingView = (trainingId) => {
  try {
    xhrPost(JsRoutes.track_training_stats_path(), {
      data: {
        training_id: trainingId,
      },
    });
  } catch (err) {
    console.log(`Couldn't track training view: ${err.message}`);
  }
};

export default trackTrainingView;
