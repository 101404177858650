import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { MAIL_FORMAT } from "../../../patterns";
import FieldError from "../../FieldError";
import JsRoutes from "../../../util/routes";
import xhrPost from "../../../util/xhrPost";
import Select from "react-select";

const AccountForm = ({ value, onSubmit, onCancel }) => {
  const { register, handleSubmit, setError, errors, control } = useForm({
    defaultValues: {
      email: value?.email,
      district: value?.district,
      school: value?.school,
    },
  });
  const [email, setEmail] = useState(value?.email);
  const [domainAllowed, setDomainAllowed] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [schools, setSchools] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState("");
  const prevEmail = usePrevious(email);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  useEffect(() => {
    if (!districts.length) fetchDistricts();
  });

  useEffect(() => {
    if (email && email !== "" && email !== prevEmail) validateEmailRules();
  }, [email]);

  useEffect(() => {
    if (value?.district) {
      let districtOption = districtOptions.find(
        (option) => option.value === value?.district.value
      );
      if (districtOption) {
        setSelectedDistrict(districtOption);
      }
    }
  }, [districts]);

  useEffect(() => {
    if (selectedDistrict) fetchSchools();
  }, [selectedDistrict]);

  const districtOptions = districts.map((d) => {
    return {
      label: d.name,
      value: d.id,
    };
  });

  const supportEmailValues = {
    email: "support@texastlaconline.org",
    body: `District: ${selectedDistrict?.label}%0D%0A School: ${
      selectedSchool?.name
    }%0D%0A Email: ${email || ""}`,
    subject: "Texas TLAC Online sign-up process support needed",
  };

  const fetchDistricts = () => {
    fetch(JsRoutes.districts_path())
      .then((r) => r.json())
      .then((districts) => {
        setDistricts(districts);
      });
  };

  const handleChangeDistrict = (option) => {
    setSelectedDistrict(option[0]);
  };

  const handleChangeSchool = (event) => {
    let schoolId = event.target.value;
    let selectedOption = schools.find(
      (school) => school.id === parseInt(schoolId)
    );
    setSelectedSchool(selectedOption);
  };

  const fetchSchools = () => {
    fetch(JsRoutes.schools_path({ district: selectedDistrict?.value }))
      .then((r) => r.json())
      .then((schools) => {
        setSchools(schools);
        setSelectedSchool(schools[0]);
      });
  };

  const validateEmailRules = () => {
    checkDomainAllowed();
    checkEmailTaken();
  };

  const checkEmailTaken = () => {
    xhrPost(JsRoutes.ensure_registration_email_path(), {
      data: { email: email },
      success: (response) => {
        setEmailTaken(false);
      },
      error: () => {
        setEmailTaken(true);
        setError("email", "taken", "has already been registered");
      },
    });
  };

  const checkDomainAllowed = () => {
    let domainNotAllowedMessage =
      "Please use your district email address when registering. Contact us below if you need support.";

    fetch(JsRoutes.allowed_domain_path({ email: email }))
      .then((r) => r.json())
      .then((response) => {
        setDomainAllowed(response.allowed);
        setError(
          "email",
          "not allowed",
          response.allowed ? "" : domainNotAllowedMessage
        );
      });
  };

  const validData = () => {
    return domainAllowed && !emailTaken && selectedDistrict;
  };

  const submitAccount = (account) => {
    account.district = selectedDistrict;
    return onSubmit(account);
  };

  return (
    <form onSubmit={handleSubmit(submitAccount)} className="w-full">
      <div>
        <div className="flex">
          <div className="w-4/5">
            <h3 className="text-lg leading-none my-6 font-bold">
              Create Account
            </h3>
            <div className="form-inputs white-bg-inputs">
              {districts && (
                <div className="input w-4/5">
                  <label>
                    Select your District{" "}
                    {!selectedDistrict && (
                      <small className="text-red">*required</small>
                    )}
                  </label>
                  <Controller
                    as={
                      <Select
                        value={districtOptions.find(
                          (o) => o.value === selectedDistrict?.value
                        )}
                        noOptionsMessage={() => "No more options"}
                        options={districtOptions}
                        filterOption={(option, inputValue) =>
                          option.label
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                      />
                    }
                    control={control}
                    name="district"
                    onChange={handleChangeDistrict}
                  />
                </div>
              )}
              {selectedDistrict && (
                <div className="input w-4/5">
                  <label>
                    Select your School{" "}
                    {!selectedSchool && (
                      <small className="text-red">*required</small>
                    )}
                  </label>
                  <select
                    className="md:mr-4"
                    name="school_id"
                    value={selectedSchool?.id}
                    onChange={handleChangeSchool}
                    ref={register({ required: "is required" })}
                  >
                    {schools.map((school) => (
                      <option
                        value={school.id}
                        key={`school-option-${school.id}`}
                      >
                        {school.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="input">
                <label>
                  District Email{" "}
                  {!email && <small className="text-red">*required</small>}
                </label>
                <input
                  className="w-4/5"
                  autoFocus="autofocus"
                  autoComplete="email"
                  type="email"
                  name="email"
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="Your e-mail address"
                  ref={register({
                    required: "is required",
                    pattern: { value: MAIL_FORMAT, message: "is invalid" },
                  })}
                />
                <FieldError>{errors.email?.message}</FieldError>
              </div>
            </div>
          </div>
          <div />
        </div>

        <div className="mt-4">
          <button className="button-form mr-4" onClick={onCancel}>
            Back
          </button>
          <button
            type="submit"
            className="button-primary"
            disabled={validData() ? "" : "disabled"}
          >
            Next
          </button>
        </div>
      </div>

      <div className="mt-4">
        <p className="mt-2">
          Can't find your district/school? Having trouble signing up?{" "}
          <a
            className="link"
            href={`mailto: ${supportEmailValues.email}?subject=${supportEmailValues.subject}&body=${supportEmailValues.body}`}
          >
            Click here for support
          </a>
        </p>
      </div>
    </form>
  );
};

export default AccountForm;
