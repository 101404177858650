import React from "react";
import PropTypes from "prop-types";
import Markdown from "./Markdown";

const Alert = ({ trainingComponent }) => (
  <div className="bg-light-grey-02 py-4 text-center border-light-grey-02-border border my-6 training-component-alert">
    <Markdown source={trainingComponent.data.text} />
  </div>
);

Alert.propTypes = {
  trainingComponent: PropTypes.object,
};

export default Alert;
