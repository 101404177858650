import React from "react";
import PropTypes from "prop-types";
import Markdown from "./Markdown";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import PracticeRecord from "./PracticeRecord";

@observer
class PracticeWriteRecordExemplar extends PracticeRecord {
  @observable step = 1;

  displayStep(...steps) {
    return steps.some((s) => s === this.step) ? "block" : "none";
  }

  @action.bound
  onNext(e) {
    e.preventDefault();
    this.step++;
  }

  render() {
    const prompts = this.props.trainingComponent.data.prompts;
    const id = this.props.trainingComponent.id;
    return (
      <div className="row">
        <div className="col-md-5">
          <Markdown source={prompts[this.step - 1].text} />
          {this.step === 3 ? <div>{this.input.value}</div> : null}
        </div>
        <div className="col-md-7">
          <form
            action={`#PracticeWriteRecordExemplar-${id}`}
            className="rl-component-form"
            style={{ display: this.displayStep(1, 2) }}
            onSubmit={this.onNext}
          >
            <textarea
              ref={(e) => (this.input = e)}
              className="form-control u-margin-bottom"
              style={{ height: "200px" }}
              aria-label={prompts[this.step - 1].text}
              name={`main_input[${id}]`}
              defaultValue={this.defaultValue}
            />

            <p className="text-right">
              <button type="submit" className="button-form">
                {this.step >= 2 ? "Record" : "Next"}
              </button>
            </p>
          </form>
          <div style={{ display: this.displayStep(3) }}>{this.cameraTag()}</div>
        </div>
      </div>
    );
  }
}

PracticeWriteRecordExemplar.propTypes = {
  trainingComponent: PropTypes.object,
};

export default PracticeWriteRecordExemplar;
