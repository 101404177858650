import React from "react";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';

const TexasTestimonials = ({testimonials}) => {
  const renderSlide = ({role, message, image}) => {
    return (
      <SwiperSlide key={role} className='cursor-grab'>
        <div className="bg-light-grey-02 p-10 flex flex-wrap md:flex-no-wrap justify-center items-center" style={{minHeight: '16rem'}}>
          <div className="text-center md:hidden">
            <img src={image} className="rounded rounded-full mx-auto mb-4" style={{maxWidth: '8rem'}} alt=""></img>
            <h3 className="font-bold text-xl leading-none mb-4">{role}</h3>
          </div>
          <div className="hidden md:block md:w-1/5 mb-5 flex md:mb-0 md:mr-10">
            <img src={image} className="rounded rounded-full mr-5" style={{maxWidth: '8rem'}} alt=""></img>
          </div>
          <div className="md:w-4/5 flex flex-wrap md:flex-no-wrap">
            <div>
              <div className="hidden md:block">
                <h3 className="font-bold text-xl leading-none">{role}</h3>
              </div>
              <p className="mt-2 text-sm">
                {message}
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>
    )
  }

  return (
    <div className="flex items-center max-w-55rem w-full mx-auto py-16 x-padded">
      <button className="prev-testimonial hidden md:block next outline-none focus:outline-none text-5xl text-white right-full pr-8" title= "previous item"><i className="fas fa-angle-left"></i></button>
      <Swiper
        modules={[Navigation, A11y]}
        navigation={{
          prevEl: '.prev-testimonial',
          nextEl: '.next-testimonial',
        }}
        loop
      >
        {testimonials.map(testimonial => renderSlide(testimonial))}
      </Swiper>
      <button className="next-testimonial hidden md:block outline-none focus:outline-none text-5xl text-white pl-8" title="next item"><i className="fas fa-angle-right"></i></button>
    </div>
  )
}

export default TexasTestimonials;
