import React, { useState, useEffect } from "react";
import JsRoutes from "../util/routes";
import getMetaValue from "../util/getMetaValue";
import Modal from "./Modal";
import gaTrackEvent from "../gaTrackEvent";

const RequestDemo = ({ onChangeAuthType }) => {
  const [requestDemoForm, updateRequestDemoForm] = useState({
    name: "",
    email: "",
    role: "",
    comment: "",
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    updateRequestDemoForm({
      ...requestDemoForm,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(JsRoutes.request_demo_path(), {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": getMetaValue("csrf-token"),
      },
      method: "POST",
      body: JSON.stringify({
        demo: requestDemoForm,
      }),
    });

    if (response.ok) {
      setSuccess("Thanks! We will contact you shortly.");
    } else {
      setError(responseData.error);
    }
  };

  const errorMessage = error ? <p className="error-message">{error}</p> : null;

  return (
    <div className="px-3-5rem pb-12 text-primary-grey-blue max-w-4xl">
      <h1 className="leading-none text-primary-grey-blue-02 mb-12">
        Request a Demo
      </h1>
      {success ? (
        <p className="p-10 bg-white success-message">{success}</p>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="form-inputs p-10 bg-white white-bg-inputs"
        >
          <div className="input">
            <input
              id="name"
              className="w-3/4"
              name="name"
              type="name"
              placeholder="Name"
              onChange={handleChange}
              value={requestDemoForm.name}
              required={true}
            />
          </div>
          <div className="input">
            <input
              id="email"
              className="w-3/4"
              name="email"
              type="email"
              placeholder="Email"
              onChange={handleChange}
              value={requestDemoForm.email}
              required={true}
            />
          </div>
          <div className="input">
            <select
              id="role"
              className="w-3/4"
              name="role"
              type="role"
              onChange={handleChange}
              value={requestDemoForm.role}
              required={true}
            >
              <option value="">Role</option>
              <option valur="Teacher">Teacher</option>
              <option valur="School Leader">School Leader</option>
              <option valur="District/Network Leader">
                District/Network Leader
              </option>
              <option valur="School of Education Leader">
                School of Education Leader
              </option>
              <option valur="Other">Other</option>
            </select>
          </div>
          <div className="input">
            <textarea
              id="comment"
              className="w-3/4"
              name="comment"
              type="comment"
              placeholder="Anything you'd like to share before we connect?"
              onChange={handleChange}
              value={requestDemoForm.comment}
              required={false}
            />
          </div>
          {errorMessage}
          <button type="submit" className="button-primary mt-4">
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

const TakeATour = ({ onNext }) => {
  const onReqDemo = (e) => {
    e.preventDefault();
    gaTrackEvent("demo", "tour-button", "click");
    onNext();
  };
  const handleSignUp = (e) => {
    gaTrackEvent("tour", "tour-button", "click");
  };
  return (
    <div className="px-3-5rem pb-12 text-primary-grey-blue max-w-4xl">
      <h1 className="leading-none text-primary-grey-blue-02 mb-8">
        Take a Tour
      </h1>
      <div className="tour-vid-container">
        <iframe
          src="https://player.vimeo.com/video/491361116?portrait=0&amp;rel=0"
          frameborder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowfullscreen=""
          data-type="vimeo"
          title="Video of interview or or classroom recording describing the training technique"
          id="video-8128cff34090451733312512bb90bf79"
        ></iframe>
      </div>
      <div className="flex flex-responsive items-center p-6 bg-white mt-6">
        <a
          href={JsRoutes.new_user_registration_path()}
          onClick={handleSignUp}
          className="button-primary md:mr-10"
        >
          Sign Up Now
        </a>
        <p className="mt-2 md:mt-0">
          Want to learn even more about how we can help?{" "}
          <a
            href="#request-a-demo"
            onClick={onReqDemo}
            className="block md:inline link"
          >
            Request a demo
          </a>
        </p>
      </div>
    </div>
  );
};

const RequestDemoModal = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen || false);
  const [step, setStep] = useState(1);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      const target = e.target;
      if (target.classList.contains("request-demo-trigger")) {
        e.preventDefault();
        gaTrackEvent("tour", "header-button", "click");
        setIsOpen(true);
      }
    });
  }, []);

  const closeModal = () => {
    setIsOpen(false);
    setStep(1);
  };

  const nextStep = () => setStep(2);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      {step == 1 ? <TakeATour onNext={nextStep} /> : <RequestDemo />}
    </Modal>
  );
};

export default RequestDemoModal;
