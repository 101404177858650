import gaTrackEvent from "../gaTrackEvent";

export default class Static {
  resources() {
    document.querySelectorAll("a.track-resource-dl").forEach((a) => {
      a.addEventListener("click", (e) => {
        let label = a.href.split("/");
        label = label[label.length - 1];
        label = label.split("-");
        label.pop();
        label = label.join("-").replace(/%20/g, " ");
        gaTrackEvent("resources", "download", label);
      });
    });
  }
}
