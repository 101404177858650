import { action, computed } from "mobx";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { SortableHandle } from "react-sortable-hoc";
import Alert from "./editors/Alert";
import Audio from "./editors/Audio";
import Chapter from "./editors/Chapter";
import Closing from "./editors/Closing";
import Image from "./editors/Image";
import Materials from "./editors/Materials";
import PracticeRecordReview from "./editors/PracticeRecordReview";
import PracticeTchart from "./editors/PracticeTchart";
import PracticeWrite from "./editors/PracticeWrite";
import PracticeWriteAudioExemplar from "./editors/PracticeWriteAudioExemplar";
import PracticeWriteExemplar from "./editors/PracticeWriteExemplar";
import PracticeWriteRecord from "./editors/PracticeWriteRecord";
import PracticeWriteRecordExemplar from "./editors/PracticeWriteRecordExemplar";
import Review from "./editors/Review";
import Table from "./editors/Table";
import Text from "./editors/Text";
import TextAndImage from "./editors/TextAndImage";
import Video from "./editors/Video";
import VideoGallery from "./editors/VideoGallery";

const DragHandle = SortableHandle(() => (
  <span className="button-form cursor-grab">
    <i className="fas fa-bars mr-1"></i>
    Reorder
  </span>
));

@observer
class TrainingComponent extends Component {
  constructor(props) {
    super(props);
    this.parentRef = React.createRef();
  }

  componentDidMount() {
    if (!this.props.trainingComponent.isPersisted) {
      window.scrollTo({
        top: this.parentRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }

  @action.bound
  handleTypeChange(event) {
    this.props.trainingComponent.changeType(event.target.value);
  }

  @action.bound
  handleSectionChange(event) {
    this.props.trainingComponent.section = event.target.value;
  }

  @action.bound
  handleDelete(event) {
    event.preventDefault();
    this.props.onDelete();
  }

  @computed
  get editor() {
    switch (this.props.trainingComponent.type) {
      case "TrainingComponents::Alert":
        return <Alert trainingComponent={this.props.trainingComponent} />;
      case "TrainingComponents::Audio":
        return (
          <Audio
            training={this.props.training}
            trainingComponent={this.props.trainingComponent}
          />
        );
      case "TrainingComponents::Chapter":
        return <Chapter trainingComponent={this.props.trainingComponent} />;
      case "TrainingComponents::Image":
        return <Image trainingComponent={this.props.trainingComponent} />;
      case "TrainingComponents::Materials":
        return <Materials trainingComponent={this.props.trainingComponent} />;
      case "TrainingComponents::Table":
        return <Table trainingComponent={this.props.trainingComponent} />;
      case "TrainingComponents::Text":
        return <Text trainingComponent={this.props.trainingComponent} />;
      case "TrainingComponents::TextAndImage":
        return (
          <TextAndImage trainingComponent={this.props.trainingComponent} />
        );
      case "TrainingComponents::PracticeRecordReview":
        return (
          <PracticeRecordReview
            trainingComponent={this.props.trainingComponent}
          />
        );
      case "TrainingComponents::PracticeTchart":
        return (
          <PracticeTchart trainingComponent={this.props.trainingComponent} />
        );
      case "TrainingComponents::PracticeWrite":
        return (
          <PracticeWrite trainingComponent={this.props.trainingComponent} />
        );
      case "TrainingComponents::PracticeWriteExemplar":
        return (
          <PracticeWriteExemplar
            trainingComponent={this.props.trainingComponent}
          />
        );
      case "TrainingComponents::PracticeWriteAudioExemplar":
        return (
          <PracticeWriteAudioExemplar
            training={this.props.training}
            trainingComponent={this.props.trainingComponent}
          />
        );
      case "TrainingComponents::PracticeWriteRecord":
        return (
          <PracticeWriteRecord
            trainingComponent={this.props.trainingComponent}
          />
        );
      case "TrainingComponents::PracticeWriteRecordExemplar":
        return (
          <PracticeWriteRecordExemplar
            trainingComponent={this.props.trainingComponent}
          />
        );
      case "TrainingComponents::Review":
        return <Review trainingComponent={this.props.trainingComponent} />;
      case "TrainingComponents::Video":
        return <Video trainingComponent={this.props.trainingComponent} />;
      case "TrainingComponents::VideoGallery":
        return (
          <VideoGallery trainingComponent={this.props.trainingComponent} />
        );
      case "TrainingComponents::Closing":
        return <Closing trainingComponent={this.props.trainingComponent} />;
      default:
        return null;
    }
  }

  @computed get sections() {
    return this.props.store.training.sections;
  }

  render() {
    return (
      <div
        ref={this.parentRef}
        className="training-component-editable form-inputs input-full white-bg-inputs"
      >
        <div className="flex">
          <div className="flex flex-grow">
            <div className="input w-1/2 mr-4">
              <label htmlFor={`tc-${this.props.trainingComponent.id}-section`}>
                Section
              </label>
              <select
                value={this.props.trainingComponent.section}
                onChange={this.handleSectionChange}
                className="bg-white"
                name="component-editor-section"
                id={`tc-${this.props.trainingComponent.id}-section`}
              >
                {this.sections.map((section) => (
                  <option
                    value={section.id}
                    key={`ts-option-${this.props.trainingComponent.id}-${section.id}`}
                  >
                    {section.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="input w-1/2">
              <label htmlFor={`tc-${this.props.trainingComponent.id}-type`}>
                Component Type
              </label>
              <select
                value={this.props.trainingComponent.type || ""}
                onChange={this.handleTypeChange}
                className="bg-white"
                name="component-editor-type"
                id={`tc-${this.props.trainingComponent.id}-type`}
              >
                <option value="">Choose Your Component</option>
                <option value="TrainingComponents::Alert">Alert</option>
                <option value="TrainingComponents::Audio">Audio</option>
                <option value="TrainingComponents::Chapter">Chapter</option>
                <option value="TrainingComponents::Image">Image</option>
                <option value="TrainingComponents::Materials">Materials</option>
                <option value="TrainingComponents::Table">Table</option>
                <option value="TrainingComponents::Text">Text</option>
                <option value="TrainingComponents::TextAndImage">
                  Text And Image
                </option>
                <option value="TrainingComponents::PracticeRecordReview">
                  Practice - Record and Review
                </option>
                <option value="TrainingComponents::PracticeTchart">
                  Practice - T-Chart
                </option>
                <option value="TrainingComponents::PracticeWrite">
                  Practice - Write
                </option>
                <option value="TrainingComponents::PracticeWriteExemplar">
                  Practice - Write &amp; Exemplar
                </option>
                <option value="TrainingComponents::PracticeWriteAudioExemplar">
                  Practice - Write &amp; Audio Exemplar
                </option>
                <option value="TrainingComponents::PracticeWriteRecord">
                  Practice - Write &amp; Record
                </option>
                <option value="TrainingComponents::PracticeWriteRecordExemplar">
                  Practice - Write Record &amp; Exemplar
                </option>
                <option value="TrainingComponents::Review">Review</option>
                <option value="TrainingComponents::Video">Video</option>
                <option value="TrainingComponents::VideoGallery">
                  Video Gallery
                </option>
                <option value="TrainingComponents::Closing">Closing</option>
              </select>
            </div>
          </div>
          <div className="w-1/3 text-right">
            <DragHandle />

            <button className="button-danger ml-4" onClick={this.handleDelete}>
              Delete
            </button>
          </div>
        </div>
        {this.editor}
      </div>
    );
  }
}

TrainingComponent.propTypes = {
  trainingComponent: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    data: PropTypes.object,
    changeType: PropTypes.func,
    isPersisted: PropTypes.bool,
  }),
};

export default TrainingComponent;
