import React, { useEffect, useState } from "react";
import { debounce } from "throttle-debounce";
import ContactLists from "./ContactLists";
import JsRoutes from "../../../util/routes";
import xhrPost from "../../../util/xhrPost";
import practices from "../../../stores/practices";
import Modal from "../../Modal";
import gaTrackEvent from "../../../gaTrackEvent";

const MAIL_FORMAT = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;

const Share = ({
  isOpen,
  onRequestClose,
  user,
  closingType,
  training,
  trainingComponent,
  trainingResult,
}) => {
  const [contactList, setContactList] = useState(null);
  const [working, setWorking] = useState(false);
  const [sendToSelf, setSendToSelf] = useState(false);
  const [emailText, setEmailText] = useState("");
  const [emails, setEmails] = useState([]);
  const [listName, setListName] = useState("");
  const [shareComplete, setShareComplete] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen) {
      return;
    }

    setContactList(null);
    setWorking(false);
    setSendToSelf(false);
    setEmailText("");
    setEmails([]);
    setListName("");
    setError(null);
    setShareComplete(false);
  }, [isOpen]);

  const handleUpdateEmails = debounce(400, (newText) => {
    const newEmails = newText
      .replace(/\s/g, "")
      .split(",")
      .map((e) => e.toLowerCase());
    setEmails(
      Array.from(new Set(newEmails.filter((e) => e.match(MAIL_FORMAT))))
    );
  });

  const handleChangeContactList = (newList) => {
    setContactList(newList);
    if (newList) {
      setEmailText(newList.contacts.join(", "));
      setEmails(newList.contacts);
    } else {
      setEmailText("");
      setEmails([]);
    }
  };

  const handleChangeSendToSelf = (e) => {
    setSendToSelf(e.target.checked);
  };

  const handleChangeEmailText = (e) => {
    setEmailText(e.target.value);
    handleUpdateEmails(e.target.value);
  };

  const handleChangeListName = (e) => {
    setListName(e.target.value);
  };

  const shareResult = (result) => {
    if (!trainingResult) {
      xhrPost(JsRoutes.track_progress_path(), {
        data: {
          progress: {
            section: training.sections.slice(-1)[0],
          },
          training_id: training.id,
        },
      });
    }

    const path = trainingResult
      ? JsRoutes.reshare_training_result_path(trainingResult.id)
      : JsRoutes.share_training_path(trainingComponent.trainingId);

    gaTrackEvent(
      "Module",
      trainingResult ? "reshare" : "share",
      `${training.id} - ${training.name}`
    );

    xhrPost(path, {
      data: {
        result,
      },
      success: () => {
        setShareComplete(true);
        setWorking(false);
        window.setTimeout(() => {
          window.location = JsRoutes.my_progress_path();
        }, 3000);
      },
      error: (e) => {
        setError(
          "There was a problem when sharing your practice. Please try again later."
        );
        setWorking(false);
      },
    });
  };

  const handleShare = () => {
    setWorking(true);

    const result = {
      contact_list_id: contactList ? contactList.id : null,
      email_myself: sendToSelf,
      emails,
      contact_list_name: listName,
    };

    if (trainingResult) {
      result.training_result_id = trainingResult.id;
      shareResult(result);
      return;
    } else {
      result.type = closingType;
    }

    const lastPractice = practices.last();

    if (closingType === "video") {
      result.video_id = lastPractice.camera_tag_id;
      shareResult(result);
    } else if (closingType === "planning") {
      result.content = lastPractice.content;
      shareResult(result);
    }
  };

  const shareSuccess = () => (
    <div className="text-center p-10 bg-white">
      <p className="font-bold">Success! Your practice has been sent.</p>
      <p>
        You are being redirected to the{" "}
        <a className="link" href={JsRoutes.my_progress_path()}>
          progress page
        </a>
        .
      </p>
    </div>
  );

  const shareForm = () => (
    <div className="p-8 bg-white">
      <div className="text-sm">
        <label htmlFor="share-with" className="mr-4">
          Share With
          <ContactLists user={user} onChange={handleChangeContactList} />
        </label>
        <label htmlFor="send-copy-self">
          <input
            id="send-copy-self"
            type="checkbox"
            className="leading-tight mr-2"
            checked={sendToSelf}
            onChange={handleChangeSendToSelf}
          />
          Send a copy to myself
        </label>
      </div>
      <textarea
        className="input w-full mt-6 input-raw"
        placeholder="Add email addresses, separated by commas"
        value={emailText}
        onChange={handleChangeEmailText}
      ></textarea>
      {contactList ? null : (
        <input
          className="input mt-2 input-raw"
          type="text"
          placeholder="Save this list as..."
          value={listName}
          onChange={handleChangeListName}
          disabled={contactList ? true : false}
        />
      )}
      {error ? <div className="text-red mt-6 text-sm">{error}</div> : null}
      <div>
        <button
          className="button-primary mt-8"
          onClick={handleShare}
          disabled={emails.length === 0 || working}
        >
          Share your practice
        </button>
      </div>
    </div>
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="pb-8 px-12 text-primary-grey-blue">
        <h1>Share</h1>
        <div className="mt-8">
          <div className="bg-light-grey-03 text-sm font-bold px-8 py-2">
            Share your practice with a colleague to get feedback.
          </div>
          {shareComplete ? shareSuccess() : shareForm()}
        </div>
      </div>
    </Modal>
  );
};

export default Share;
