import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MAIL_FORMAT, PWD_FORMAT } from "../../../patterns";
import xhrPost from "../../../util/xhrPost";
import FieldError from "../../FieldError";
import JsRoutes from "../../../util/routes";
import { PlansList } from "./SignUpSubscription";

const AccountType = ({ onChange, isGroup = false }) => {
  const [currentIsGroup, setIsGroup] = useState(isGroup);

  const handleChangeGroup = (e) => {
    const isGroup = e.target.value == "true" && e.target.checked;
    setIsGroup(isGroup);
    onChange({ group: isGroup });
  };

  return (
    <div className="form-inputs">
      <div id="subscriptionPlanPicker" onChange={handleChangeGroup}>
        <div className="my-6 radio_boxes flex">
          <div className="radiobox mr-8">
            <input
              type="radio"
              id="group-false"
              name="group"
              value="false"
              checked={!currentIsGroup}
              onChange={handleChangeGroup}
            />
            <label htmlFor="group-false">
              A single account for an individual
            </label>
          </div>
          <div className="radiobox">
            <input
              type="radio"
              id="group-true"
              name="group"
              value="true"
              checked={currentIsGroup}
              onChange={handleChangeGroup}
            />
            <label htmlFor="group-true">Multiple accounts for your group</label>
          </div>
        </div>
      </div>
    </div>
  );
};

const AccountForm = ({
  value,
  onSubmit,
  signedIn = false,
  subscriptionPlans = [],
  texas = false,
}) => {
  const { register, handleSubmit, watch, setError, errors, setValue } = useForm(
    {
      defaultValues: {
        email: value?.email,
        password: value?.password,
        password_confirmation: value?.password_confirmation,
        isGroup: value?.isGroup ? true : false,
        groupName: value?.groupName,
      },
    }
  );

  useEffect(() => {
    register({ name: "isGroup" });
  }, []);

  const isGroup = watch("isGroup", value?.isGroup ? true : false);

  const validatePasswordConfirmation = (value) => {
    return value !== watch("password") ? "must match password" : undefined;
  };

  const handleAccountType = ({ group }) => setValue("isGroup", group);

  const passwordRef = register({
    required: "is required",
    pattern: {
      value: PWD_FORMAT,
      message: "must mix letters (upper AND lower), digits and symbols",
    },
  });

  const submitAccount = (account) => {
    xhrPost(JsRoutes.ensure_registration_email_path(), {
      data: {
        email: account.email,
      },
      success: () => {
        onSubmit(account);
      },
      error: () => {
        setError("email", "taken", "has already been registered");
      },
    });
  };

  const profileFields = () => {
    return (
      <div>
        <div className="input">
          <label htmlFor="first-name">
            First Name<small>*</small>
          </label>
          <input
            className="w-full"
            id="first-name"
            name="first_name"
            placeholder="First Name"
            ref={register({
              required: "is required",
              minLength: {
                value: 2,
                message: "should be longer than 1 character",
              },
            })}
          />
          <FieldError>{errors.first_name?.message}</FieldError>
        </div>
        <div className="input">
          <label htmlFor="last-name">
            Last Name<small>*</small>
          </label>
          <input
            className="w-full"
            id="last-name"
            name="last_name"
            placeholder="Last Name"
            ref={register({
              required: "is required",
              minLength: {
                value: 2,
                message: "should be longer than 1 character",
              },
            })}
          />
          <FieldError>{errors.last_name?.message}</FieldError>
        </div>
      </div>
    );
  };

  const accountTitle = isGroup
    ? "Create Group Subscription"
    : "Create Single Subscription";

  return (
    <form onSubmit={handleSubmit(submitAccount)} className="w-full">
      <div>
        <p className="text-sm my-4">
          Please select a plan. Individual and group accounts are available.
        </p>
        <h3 className="text-lg leading-none my-6 font-bold">Account Type</h3>
        <AccountType onChange={handleAccountType} isGroup={value?.isGroup} />
      </div>
      <hr />
      <div>
        <div className="flex">
          <div className="w-3/5">
            <h3 className="text-lg leading-none my-6 font-bold">
              {accountTitle}
            </h3>
            <div className="form-inputs white-bg-inputs">
              {isGroup ? (
                <div className="input">
                  <label>
                    Your Group's Name<small>*</small>
                  </label>
                  <input
                    className="w-full"
                    type="text"
                    placeholder="A name to help you identify the group."
                    name="groupName"
                    ref={register({
                      required: "is required",
                    })}
                  />
                  <FieldError>{errors.groupName?.message}</FieldError>
                </div>
              ) : null}

              {signedIn ? (
                <div className="row u-margin-bottom">
                  <div className="input">
                    <label>
                      Password<small>*</small>
                    </label>
                    <input
                      className="w-full"
                      autoComplete="new-password"
                      type="password"
                      name="password"
                      aria-describedby="passwordHelpBlock"
                      placeholder="Type in your current password to confirm the renewal"
                      ref={passwordRef}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  {profileFields()}
                  <div className="input">
                    <label>
                      Email<small>*</small>
                    </label>
                    <input
                      className="w-full"
                      autoFocus="autofocus"
                      autoComplete="email"
                      type="email"
                      name="email"
                      placeholder="Your e-mail address"
                      ref={register({
                        required: "is required",
                        pattern: { value: MAIL_FORMAT, message: "is invalid" },
                      })}
                    />
                    {texas ? (
                      <small className="mt-2">
                        Please use your district or university-provided email
                        address when registering. No personal email addresses
                        from gmail.com, yahoo.com, or similar will be accepted.
                      </small>
                    ) : null}
                    <FieldError>{errors.email?.message}</FieldError>
                  </div>
                  <div className="row u-margin-bottom">
                    <div className="input">
                      <label>
                        Choose Password<small>*</small>
                      </label>
                      <input
                        className="w-full"
                        autoComplete="new-password"
                        type="password"
                        name="password"
                        aria-describedby="passwordHelpBlock"
                        placeholder="Choose a password"
                        ref={passwordRef}
                      />
                      <p className="mt-2 text-xxs">
                        Your password must be at least 6 characters long and
                        have a mix of upper and lower case letters, numbers and
                        symbols.
                      </p>
                      <FieldError>{errors.password?.message}</FieldError>
                    </div>
                    <div className="input">
                      <label>
                        Confirm Password<small>*</small>
                      </label>
                      <input
                        className="w-full"
                        autoComplete="new-password "
                        type="password"
                        name="password_confirmation"
                        placeholder="Confirm password"
                        ref={register({
                          required: "is required",
                          validate: validatePasswordConfirmation,
                        })}
                      />
                      <FieldError>
                        {errors.password_confirmation?.message}
                      </FieldError>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {isGroup && (
            <div className="lg:w-2/5 my-6 mx-3">
              <PlansList subscriptionPlans={subscriptionPlans} step={1} />
            </div>
          )}
          <div></div>
        </div>

        <div className="mt-4">
          <button type="submit" className="button-primary">
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default AccountForm;
