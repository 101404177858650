import { getNames } from "country-list";
import { add as addDate, parse as parseDate } from "date-fns";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { debounce } from "throttle-debounce";
import { braintreeHostedFields } from "../../../braintree";
import FieldError from "../../FieldError";
import JsRoutes from "../../../util/routes";
import { PlansList } from "./SignUpSubscription";

const formatPrice = (x) => parseFloat(x).toFixed(2).replace(/\.0+$/, "");

const formatDate = (date) => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
};

const shortParseDate = (date) => {
  return parseDate(date, "yyyy-MM-dd", new Date());
};

const getSubscriptionDetails = (subscriptionPlans, account, numAccounts) => {
  if (account.isGroup) {
    const plan = subscriptionPlans.find((p) => {
      return p.min <= numAccounts && p.max >= numAccounts;
    });
    return {
      accounts: numAccounts,
      group: true,
      groupName: account.groupName,
      plan,
    };
  } else {
    const singleUserPlans = subscriptionPlans.filter((s) => !s.group);
    if (singleUserPlans.length == 1) {
      return {
        group: false,
        plan: singleUserPlans[0],
      };
    }
    return undefined;
  }
};

const PaymentForm = ({
  onCancel,
  onSubmit,
  disabled,
  account,
  subscriptionPlans,
  renewal,
  upgrade,
}) => {
  const { register, handleSubmit, watch, setError, errors } = useForm();
  const [hostedFields, setHostedFields] = useState(null);
  const [localDisable, setLocalDisable] = useState(disabled);
  const [payBy, setPayBy] = useState("cc");
  const [userDiscountCode, setUserDiscountCode] = useState("");
  const [discountCode, setDiscountCode] = useState(null);
  const [discountCodeError, setDiscountCodeError] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [numAccounts, setNumAccounts] = useState(() => {
    if (isAddUsersUpgrade) {
      return 1;
    } else if (renewal) {
      return renewal.numAccounts;
    }
    return "";
  });
  const [numAccountsError, setNumAccountsError] = useState(null);
  const [startAt, setStartAt] = useState(
    renewal ? shortParseDate(renewal.startDate) : new Date()
  );

  useEffect(() => {
    if (upgrade && upgrade.type == "addUsers" && !numAccounts) {
      setNumAccounts(1);
    }
  }, [upgrade, numAccounts]);

  useEffect(() => {
    let totalNumAccounts = numAccounts ? parseInt(numAccounts) : 0;
    if (isAddUsersUpgrade) {
      totalNumAccounts += upgrade.numAccounts;
    }
    const newDetails = getSubscriptionDetails(
      subscriptionPlans,
      account,
      totalNumAccounts
    );
    if (newDetails) {
      setSubscriptionDetails(newDetails);
    }
  }, [upgrade, subscriptionPlans, account, numAccounts]);

  useEffect(() => {
    if (payBy != "cc") {
      return;
    }

    braintreeHostedFields()
      .then((newHostedFields) => {
        setHostedFields(newHostedFields);
      })
      .catch((err) => {
        console.error("BT error: ", err.code, err.message);
      });

    ["bt", "number", "expirationDate", "cvv", "postalCode"].forEach((k) =>
      register({ name: k })
    );
  }, [payBy]);

  useEffect(() => setLocalDisable(disabled), [disabled]);

  useEffect(() => {
    if (!userDiscountCode) {
      setDiscountCode(null);
      setDiscountCodeError(null);
      return;
    }

    fetchDiscountCode(userDiscountCode);
  }, [userDiscountCode]);

  const isAddUsersUpgrade = upgrade && upgrade.type == "addUsers";

  const countries = getNames();

  const onChangeUserDiscountCode = (e) => setUserDiscountCode(e.target.value);

  const fetchDiscountCode = useCallback(
    debounce(1000, (coupon) => {
      fetch(JsRoutes.discount_code_path(coupon), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "GET",
      })
        .then((r) => r.json())
        .then((dc) => {
          if (dc) {
            setDiscountCode(dc);
            setDiscountCodeError(null);
          } else {
            setDiscountCode(null);
            setDiscountCodeError(
              "It looks like this coupon has expired or is not available."
            );
          }
        })
        .catch(() => {
          setDiscountCode(null);
          setDiscountCodeError(
            "It looks like this coupon has expired or is not available."
          );
        });
    }),
    []
  );

  const submitPayment = (paymentData) => {
    if (!subscriptionDetails) {
      return;
    }

    if (
      subscriptionDetails.group &&
      (!subscriptionDetails.accounts || !subscriptionDetails.groupName)
    ) {
      setNumAccountsError("Please select the number of accounts.");
      return;
    }

    if (payBy != "cc" || (discountCode && totalAfterDiscount == 0)) {
      setLocalDisable(false);
      onSubmit({
        profile: {
          first_name: paymentData.first_name,
          last_name: paymentData.last_name,
        },
        payment: {
          pay_by: payBy,
          coupon: discountCode?.coupon,
          email_consent: watch("email_consent"),
          invoice_address: watch("invoice_address"),
        },
        subscriptionPlan: {
          ...subscriptionDetails,
          accounts: numAccounts,
          startAt,
        },
      });
    }

    if (!hostedFields) {
      setError(
        "bt",
        "init",
        "Please wait for the payment solution to initialise."
      );
      return;
    }

    const btState = hostedFields.getState();
    const btValid = Object.keys(btState.fields)
      .map((key) => {
        const isValid = btState.fields[key].isValid;
        if (!isValid) {
          setError(key, "bt", "is invalid");
          return false;
        }
        return true;
      })
      .every((v) => v);

    if (!btValid) {
      return;
    }

    const cardholderName = watch("cardholder_name");
    setLocalDisable(true);
    hostedFields.tokenize({ cardholderName }, (err, payload) => {
      if (err) {
        window.alert(err.message);
        return;
      }

      setLocalDisable(false);
      onSubmit({
        profile: {
          first_name: paymentData.first_name,
          last_name: paymentData.last_name,
        },
        payment: {
          pay_by: "cc",
          coupon: discountCode?.coupon,
          nonce: payload.nonce,
          email_consent: watch("email_consent"),
        },
        subscriptionPlan: {
          ...subscriptionDetails,
          accounts: numAccounts,
          startAt,
        },
      });
    });
  };

  const handleChangePayBy = (e) => {
    const newPayBy = e.target.value;
    if (newPayBy != "cc" && hostedFields) {
      hostedFields.teardown((err) => {
        setPayBy(newPayBy);
      });
    } else {
      setPayBy(newPayBy);
    }
  };

  const handleChangeNumAccounts = (e) => {
    const newValue = e.target.value;
    if (newValue) {
      setNumAccountsError(null);
    }
    setNumAccounts(newValue);
  };

  let total = 0;
  let totalAfterDiscount = 0;

  if (subscriptionDetails?.plan) {
    if (isAddUsersUpgrade) {
      total = numAccounts * subscriptionDetails.plan.price;
    } else {
      total = parseFloat(
        subscriptionDetails.group
          ? subscriptionDetails.accounts * subscriptionDetails.plan.price
          : subscriptionDetails.plan.price
      );
    }

    totalAfterDiscount = discountCode
      ? total - total * (parseFloat(discountCode.percent_off) / 100.0)
      : total;
    totalAfterDiscount = totalAfterDiscount < 1.0 ? 0 : totalAfterDiscount;
  }

  const onChangeStartAt = (e) => {
    const defaultNewDate = renewal
      ? shortParseDate(renewal.startDate)
      : new Date();
    const newDate = e.target.value
      ? shortParseDate(e.target.value)
      : defaultNewDate;
    setStartAt(newDate);
  };

  const startAtPicker = () => {
    const currentDate = startAt.toISOString().substring(0, 10);
    const minDate = renewal
      ? renewal.startDate
      : new Date().toISOString().substring(0, 10);
    return (
      <div className="mt-4">
        <span className="block font-bold mb-2">Subscription Start Date</span>
        <div className="input">
          <input
            className=""
            type="date"
            name="start_at"
            onChange={onChangeStartAt}
            min={minDate}
            value={currentDate}
          />
        </div>
      </div>
    );
  };

  const singleUserPaymentHeader = () => {
    return (
      <div className="bg-light-grey-02 p-4 mt-6 rounded-md">
        <p className="font-bold text-lg">
          {subscriptionDetails.plan.name} -&nbsp;
          {totalAfterDiscount != total ? (
            <span className="line-through">${formatPrice(total)}</span>
          ) : (
            `($${formatPrice(total)})`
          )}
          {totalAfterDiscount != total ? (
            <span className="text-green">
              &nbsp;${formatPrice(totalAfterDiscount)}
            </span>
          ) : null}
        </p>
        <p className="mt-2">{subscriptionDetails.plan.description}</p>
        <div className="input" style={{ marginBottom: 0 }}>
          <input
            className="mt-2"
            type="text"
            name="discount_code"
            placeholder="Enter Discount Code"
            onChange={onChangeUserDiscountCode}
          />
        </div>
        {discountCodeError ? (
          <div className="error-message">{discountCodeError}</div>
        ) : null}
        {startAtPicker()}
      </div>
    );
  };

  const groupPaymentHeader = () => {
    const planCost = (pl) => {
      if (!subscriptionDetails?.plan) {
        return;
      }

      const cost =
        totalAfterDiscount != total ? (
          <Fragment>
            <span className="line-through">${formatPrice(total)}</span>
            <span className="text-green">
              &nbsp;${formatPrice(totalAfterDiscount)}
            </span>
          </Fragment>
        ) : (
          <span>${formatPrice(total)}</span>
        );

      return (
        <div className="mt-4 font-bold">
          ({subscriptionDetails.plan.min}-{subscriptionDetails.plan.max} users):
          ${parseInt(subscriptionDetails.plan.price)} (per user) x {numAccounts}{" "}
          = {cost}
        </div>
      );
    };

    const planTitle = subscriptionDetails?.plan ? (
      <div className="mt-4">{subscriptionDetails.plan.description}</div>
    ) : null;

    return (
      <div className="bg-light-grey-02 p-4 mt-6 rounded-md">
        <div className="flex">
          <div className="lg:w-3/5">
            <div className="input">
              <label htmlFor="quantity">
                {isAddUsersUpgrade ? (
                  <strong>Number of new accounts</strong>
                ) : (
                  <strong>Number of accounts</strong>
                )}
              </label>
              <input
                className="w-1/4"
                type="number"
                step="1"
                id="quantity"
                min="1"
                value={numAccounts}
                onChange={handleChangeNumAccounts}
              />
              <FieldError>{numAccountsError}</FieldError>
            </div>
            <div className="input" style={{ marginBottom: 0 }}>
              <input
                type="text"
                name="discount_code"
                placeholder="Enter Discount Code"
                onChange={onChangeUserDiscountCode}
              />
            </div>
            {discountCodeError ? (
              <div className="error-message">{discountCodeError}</div>
            ) : null}
            {planCost()}
            {upgrade?.fromDashboard ? null : planTitle}
          </div>
          <div className="lg:w-2/5">
            <PlansList subscriptionPlans={subscriptionPlans} step={2} />
          </div>
        </div>
        {isAddUsersUpgrade ? null : startAtPicker()}
      </div>
    );
  };

  const accountTypeTitle = account.isGroup
    ? `${account.groupName} Group Subscription`
    : "Single Subscription";

  let subscribeButtonText = "Subscribe";

  if (renewal) {
    subscribeButtonText = "Renew Now";
  } else if (isAddUsersUpgrade) {
    subscribeButtonText = "Upgrade subscription";
  }

  const renewalLeadingInfo = () => {
    return (
      <Fragment>
        <h3 className="text-lg leading-none my-6 font-bold">
          Payment Details: {accountTypeTitle}
        </h3>
        {renewal ? (
          <div className="text-xs">
            Renewing from {formatDate(startAt)} to{" "}
            {formatDate(addDate(startAt, { years: 1 }))}
          </div>
        ) : null}
        {isAddUsersUpgrade ? (
          <div className="text-xs">
            Adding users from {formatDate(shortParseDate(upgrade.startDate))} to{" "}
            {formatDate(shortParseDate(upgrade.endDate))} | Current account{" "}
            {upgrade.numAccounts} users
          </div>
        ) : null}
      </Fragment>
    );
  };

  const accountsStartInfo = () => {
    return (
      <div className="mb-6 px-6 py-4 bg-selective-yellow rounded">
        {`Accounts will start immediately and expire on ${formatDate(
          shortParseDate(upgrade.endDate)
        )}`}
      </div>
    );
  };

  const renewalOrUpgradeBottomBtns = () => {
    return (
      <div className="mt-4">
        <button className="button-form mr-4" onClick={onCancel}>
          {renewal || isAddUsersUpgrade ? "Cancel" : "Back"}
        </button>
        <button
          className="button-primary"
          type="submit"
          disabled={localDisable}
        >
          {subscribeButtonText}
        </button>
      </div>
    );
  };

  const purchaseMoreAccountsBottomBtns = () => {
    return (
      <div className="flow-root mt-4">
        <button
          className="button-primary float-left"
          type="submit"
          disabled={localDisable}
        >
          Purchase Accounts
        </button>
        <button
          className="button-form-secondary float-right mr-4"
          onClick={onCancel}
        >
          Back
        </button>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(submitPayment)} className="w-full">
      <div className="form-inputs white-bg-inputs">
        {upgrade?.fromDashboard ? accountsStartInfo() : renewalLeadingInfo()}
        {subscriptionDetails
          ? subscriptionDetails.group
            ? groupPaymentHeader()
            : singleUserPaymentHeader()
          : null}
        {discountCode && totalAfterDiscount == 0 ? null : (
          <div>
            <div className="my-6 radio_boxes flex">
              <div className="radiobox mr-8">
                <input
                  type="radio"
                  id="pay_by-cc"
                  name="pay_by"
                  value="cc"
                  checked={payBy == "cc"}
                  onChange={handleChangePayBy}
                />
                <label htmlFor="pay_by-cc">Pay by credit card</label>
              </div>
              <div className="radiobox">
                <input
                  type="radio"
                  id="pay_by-invoice"
                  name="pay_by"
                  value="invoice"
                  checked={payBy == "invoice"}
                  onChange={handleChangePayBy}
                />
                <label htmlFor="pay_by-invoice">Pay by invoice</label>
              </div>
            </div>
            {payBy == "cc" ? (
              <div>
                <div className="bg-light-grey-02 p-4 mb-6 rounded-md">
                  Pay by credit card for immediate access.
                </div>
                <div className="flex-responsive">
                  <div className="input lg:w-1/2 lg:mr-6">
                    <label>
                      Card number<small>*</small>
                    </label>
                    <div id="card-number" className="input-raw bg-white h-10" />
                    <FieldError>{errors.number?.message}</FieldError>
                  </div>
                  <div className="flex-responsive lg:w-1/2">
                    <div className="input lg:w-1/2 lg:mr-6">
                      <label>
                        Expiration <small>(MM/YYYY)*</small>
                      </label>
                      <div
                        id="expiration-date"
                        className="input-raw bg-white h-10"
                      />
                      <FieldError>{errors.expirationDate?.message}</FieldError>
                    </div>
                    <div className="input lg:w-1/2">
                      <label>
                        Security Code <small>(CVV)*</small>
                      </label>
                      <div id="cvv" className="input-raw bg-white h-10" />
                      <FieldError>{errors.cvv?.message}</FieldError>
                    </div>
                  </div>
                </div>
                <div className="flex-responsive">
                  <div className="input lg:w-1/2 lg:mr-6">
                    <label htmlFor="cardholder-name">
                      Cardholder Name<small>*</small>
                    </label>
                    <input
                      className="w-full"
                      id="cardholder-name"
                      name="cardholder_name"
                      placeholder="Cardholder Name"
                      ref={register({
                        required: "is required",
                        minLength: {
                          value: 2,
                          message: "should be longer than 1 character",
                        },
                      })}
                    />
                    <FieldError>{errors.cardholder_name?.message}</FieldError>
                  </div>
                  <div className="input lg:w-1/2">
                    <label>
                      Zip / Postal code of billing address<small>*</small>
                    </label>
                    <div id="postal-code" className="input-raw bg-white h-10" />
                    <FieldError>{errors.postalCode?.message}</FieldError>
                  </div>
                </div>
                <FieldError>{errors.bt?.message}</FieldError>
              </div>
            ) : (
              <div>
                <div className="bg-light-grey-02 p-4 mb-6 rounded-md">
                  <p className="font-bold mb-2">Invoice Information</p>
                  <p>
                    To pay by invoice complete the fields below, review the
                    permissions, and click Subscribe. The TLAC Online team will
                    email you an invoice and activate your account in the next
                    24-72 hours.
                  </p>
                </div>
                <span className="block font-bold my-2">
                  Billing Contact Information
                </span>
                {account.isGroup ? (
                  <div className="input">
                    <label htmlFor="invoice_address.group-name">
                      Organization / School Name for Invoice<small>*</small>
                    </label>
                    <input
                      className="w-full"
                      id="invoice_address.group-name"
                      name="invoice_address.group_name"
                      ref={register({
                        required: "is required",
                        minLength: {
                          value: 2,
                          message: "should be longer than 1 character",
                        },
                      })}
                    />
                    <FieldError>
                      {errors.invoice_address?.group_name?.message}
                    </FieldError>
                  </div>
                ) : null}
                <div className="flex-responsive">
                  <div className="input lg:w-1/2 lg:mr-6">
                    <label htmlFor="invoice_address.first-name">
                      Billing Contact First Name<small>*</small>
                    </label>
                    <input
                      className="w-full"
                      id="invoice_address.first-name"
                      name="invoice_address.first_name"
                      ref={register({
                        required: "is required",
                        minLength: {
                          value: 2,
                          message: "should be longer than 1 character",
                        },
                      })}
                    />
                    <FieldError>
                      {errors.invoice_address?.first_name?.message}
                    </FieldError>
                  </div>
                  <div className="input lg:w-1/2">
                    <label htmlFor="invoice_address.last-name">
                      Billing Contact Last Name<small>*</small>
                    </label>
                    <input
                      className="w-full"
                      id="invoice_address.last-name"
                      name="invoice_address.last_name"
                      ref={register({
                        required: "is required",
                        minLength: {
                          value: 2,
                          message: "should be longer than 1 character",
                        },
                      })}
                    />
                    <FieldError>
                      {errors.invoice_address?.last_name?.message}
                    </FieldError>
                  </div>
                </div>
                <div className="input">
                  <label htmlFor="invoice_address.email">
                    Email Address<small>*</small>
                  </label>
                  <input
                    className="w-full"
                    id="invoice_address.email"
                    name="invoice_address.email"
                    type="email"
                    ref={register({
                      required: "is required",
                      minLength: {
                        value: 2,
                        message: "should be longer than 1 character",
                      },
                    })}
                  />
                  <FieldError>
                    {errors.invoice_address?.email?.message}
                  </FieldError>
                </div>
                <span className="block font-bold mb-2 mt-4">
                  Mailing Address
                </span>
                <div className="form-inputs white-bg-inputs flex-responsive">
                  <div className="w-4/6 mr-4">
                    <div className="input">
                      <label htmlFor="invoice_address.street">
                        Street Address<small>*</small>
                      </label>
                      <input
                        className="w-full"
                        autoFocus="autofocus"
                        autoComplete="street"
                        type="text"
                        name="invoice_address.street"
                        id="invoice_address.street"
                        ref={register({
                          required: "is required",
                          minLength: {
                            value: 2,
                            message: "should be longer than 1 character",
                          },
                          maxLength: {
                            value: 500,
                            message: "should be shorter than 500 characters",
                          },
                        })}
                      />
                      <FieldError>
                        {errors.invoice_address?.street?.message}
                      </FieldError>
                    </div>
                  </div>
                  <div className="w-2/6">
                    <div className="input">
                      <label htmlFor="invoice_address.suite">
                        Suite / Unit
                      </label>
                      <input
                        className="w-full"
                        autoFocus="autofocus"
                        autoComplete="suite"
                        type="text"
                        name="invoice_address.suite"
                        id="invoice_address.suite"
                        ref={register({
                          maxLength: {
                            value: 100,
                            message: "should be shorter than 100 characters",
                          },
                        })}
                      />
                      <FieldError>
                        {errors.invoice_address?.suite?.message}
                      </FieldError>
                    </div>
                  </div>
                </div>
                <div className="form-inputs white-bg-inputs flex-responsive">
                  <div className="w-4/6 mr-4">
                    <div className="input">
                      <label htmlFor="invoice_address.city">
                        City<small>*</small>
                      </label>
                      <input
                        className="w-full"
                        autoFocus="autofocus"
                        autoComplete="city"
                        type="text"
                        name="invoice_address.city"
                        id="invoice_address.city"
                        ref={register({
                          required: "is required",
                          minLength: {
                            value: 2,
                            message: "should be longer than 1 character",
                          },
                          maxLength: {
                            value: 500,
                            message: "should be shorter than 500 characters",
                          },
                        })}
                      />
                      <FieldError>
                        {errors.invoice_address?.city?.message}
                      </FieldError>
                    </div>
                  </div>
                  <div className="w-2/6">
                    <div className="input">
                      <label htmlFor="invoice_address.state">
                        State / Province<small>*</small>
                      </label>
                      <input
                        className="w-full"
                        autoFocus="autofocus"
                        autoComplete="state"
                        type="text"
                        name="invoice_address.state"
                        id="invoice_address.state"
                        ref={register({
                          required: "is required",
                          minLength: {
                            value: 2,
                            message: "should be longer than 1 character",
                          },
                          maxLength: {
                            value: 500,
                            message: "should be shorter than 500 characters",
                          },
                        })}
                      />
                      <FieldError>
                        {errors.invoice_address?.state?.message}
                      </FieldError>
                    </div>
                  </div>
                </div>
                <div className="form-inputs white-bg-inputs flex-responsive">
                  <div className="w-4/6 mr-4">
                    <div className="input">
                      <label htmlFor="invoice_address.country">Country</label>
                      <select
                        name="invoice_address.country"
                        id="invoice_address.country"
                        ref={register}
                        defaultValue="United States of America"
                      >
                        {countries.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="w-2/6">
                    <div className="input">
                      <label htmlFor="invoice_address.zip_code">
                        Zip / Postal Code<small>*</small>
                      </label>
                      <input
                        className="w-full"
                        autoFocus="autofocus"
                        autoComplete="zip_code"
                        type="text"
                        name="invoice_address.zip_code"
                        id="invoice_address.zip_code"
                        ref={register({
                          required: "is required",
                          minLength: {
                            value: 2,
                            message: "should be longer than 1 character",
                          },
                        })}
                      />
                      <FieldError>
                        {errors.invoice_address?.zip_code?.message}
                      </FieldError>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {renewal || (isAddUsersUpgrade && !upgrade.fromDashboard) ? null : (
        <div>
          <h3 className="text-lg leading-none my-6 font-bold">Permissions</h3>
          <div className="check_boxes">
            <div className="checkbox">
              <input
                className="mr-2"
                id="terms_agreement"
                type="checkbox"
                name="terms_agreement"
                ref={register({ required: "is required" })}
              />
              <label htmlFor="terms_agreement">
                I agree to the{" "}
                <a href="/pages/terms" target="_blank" className="link">
                  Terms and Conditions of Use
                </a>
                .<small>*</small>
              </label>
              <FieldError>{errors.terms_agreement?.message}</FieldError>
            </div>
            {upgrade?.fromDashboard ? null : (
              <Fragment>
                <div className="checkbox">
                  <input
                    className="mr-2"
                    id="data_consent"
                    type="checkbox"
                    name="data_consent"
                    ref={register({ required: "is required" })}
                  />
                  <label htmlFor="data_consent">
                    I consent to having my data stored on servers located in the
                    United States.<small>*</small>
                  </label>
                  <FieldError>{errors.data_consent?.message}</FieldError>
                </div>
                <div className="checkbox">
                  <input
                    className="mr-2"
                    id="email_consent"
                    type="checkbox"
                    name="email_consent"
                    ref={register}
                  />
                  <label htmlFor="email_consent">
                    Together Education may send me email about other Teach Like
                    a Champion products and services that may be of interest to
                    me.
                  </label>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      )}
      {upgrade?.fromDashboard
        ? purchaseMoreAccountsBottomBtns()
        : renewalOrUpgradeBottomBtns()}
    </form>
  );
};

export default PaymentForm;
