import React, { useState } from "react";
import gaTrackEvent from "../../gaTrackEvent";

const CreateTeams = () => {
  const [teams, setTeams] = useState([{ name: "Team 1" }]);

  const onChangeNumTeams = (e) => {
    const numTeams = e.target.value;
    const newTeams = [...teams.slice(0, numTeams > 0 ? numTeams - 1 : 0)];
    if (numTeams > 0) {
      for (let i = 0; i < Math.abs(numTeams - teams.length); i++) {
        newTeams.push({ name: `Team ${newTeams.length + 1}` });
      }
    }
    setTeams(newTeams);
  };

  const onChangeTeamName = (e) => {
    const teamIdx = parseInt(e.target.getAttribute("data-team-idx"));
    let newTeams = teams.slice(0, teamIdx);
    newTeams.push({ name: e.target.value });
    setTeams(newTeams.concat(teams.slice(teamIdx + 1)));
  };

  const validTeams = teams.filter((t) => t.name);

  const submit = () => {
    gaTrackEvent("sign-up", "create-team-2", "manager");
  };

  const skip = () => {
    setTeams([]);
    gaTrackEvent("sign-up", "create-team-2-skip", "manager");
  };

  return (
    <div className="form-inputs white-bg-inputs mt-6">
      <div className="input">
        <input
          type="number"
          value={teams.length}
          onChange={onChangeNumTeams}
          min="0"
          step="1"
        />
      </div>
      <div className="input flex flex-wrap">
        {teams.map((team, idx) => (
          <input
            className="w-full mb-4"
            type="text"
            data-team-idx={idx}
            value={team.name}
            key={`tn-${idx}`}
            onChange={onChangeTeamName}
            placeholder={`Team ${idx + 1}`}
          />
        ))}
      </div>
      <div className="hidden">
        {validTeams.map((team, idx) => (
          <input
            type="hidden"
            name="teams[][name]"
            value={team.name}
            key={`email-${idx}`}
          />
        ))}
      </div>
      <div className="flex mt-4">
        <button
          className="button-form"
          type="submit"
          disabled={validTeams.length == 0}
          onClick={submit}
        >
          Next
        </button>
        <button className="button-link" onClick={skip}>
          Skip this, I'll create teams and invite coaches later if I need them
        </button>
      </div>
    </div>
  );
};

export default CreateTeams;
