import React, { Component } from "react";
import PropTypes from "prop-types";
import practices from "../../stores/practices";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import TrainingSection from "./TrainingSection";
import JsRoutes from "../../util/routes";
import gaTrackEvent from "../../gaTrackEvent";
import trackTrainingView from "../../util/trackTrainingView";
import trackProgress from "../../util/trackProgress";

@observer
class TrainingComponents extends Component {
  @observable visibleSectionIndeces = [];
  @observable componentsBySection = new Map();

  constructor(props) {
    super(props);
    if (!this.props.previewMode) {
      trackTrainingView(props.training.id);
    }
    practices.init();
    this.filterComponents();
    this.displaySections();
  }

  get completedSections() {
    return this.props.trainingProgress?.details?.completed_sections;
  }

  filterComponents() {
    this.props.training.sections.forEach((section) =>
      this.componentsBySection.set(
        section.id,
        this.props.trainingComponents.filter((tc) => tc.section === section.id)
      )
    );
  }

  displaySections() {
    // Configure the visible sections in the training screen.
    if (this.props.training.sections.length) {
      const completedCount = this.completedSections?.length;
      if (this.props.previewMode) {
        for (let i = 0; i < this.props.training.sections.length; i++) {
          this.visibleSectionIndeces.push(i);
        }
      } else if (completedCount) {
        for (let i = 0; i < completedCount; i++) {
          this.visibleSectionIndeces.push(i);
        }
        this.visibleSectionIndeces.push(this.visibleSectionIndeces.length);
      } else {
        this.visibleSectionIndeces.push(0);
      }
    }
  }

  @action.bound
  handleNext(sectionIndex, section) {
    if (!this.visibleSectionIndeces.includes(sectionIndex + 1)) {
      this.visibleSectionIndeces.push(sectionIndex + 1);

      gaTrackEvent(
        "Module",
        `next-${sectionIndex + 1}`,
        `${this.props.training.id} - ${this.props.training.name}`
      );

      if (!this.props.config.user) {
        return;
      }

      trackProgress({
        trainingId: this.props.training.id,
        section: section.id,
      });

      this.scrollToSection(sectionIndex);
    }
  }

  scrollToSection(sectionIndex) {
    const scrollInterval = window.setInterval(() => {
      const id = this.props.training.sections[sectionIndex + 1].id;
      const scrollTarget = document.getElementById(`ts-${id}`);
      if (!scrollTarget) {
        return;
      }
      const progressHeader = document.getElementById("t-progress");
      window.scrollTo({
        behavior: "smooth",
        top: scrollTarget.offsetTop - progressHeader.offsetHeight,
      });
      clearInterval(scrollInterval);
    });
  }

  componentDidMount() {
    if (this.completedSections) {
      this.scrollToSection(this.completedSections.length - 1);
    }
  }

  render() {
    const visibleSections = this.visibleSectionIndeces.map((idx) => {
      const sectionDef = this.props.training.sections[idx];
      return {
        section: sectionDef,
        components: this.componentsBySection.get(sectionDef.id),
      };
    });

    let currentProgress = this.visibleSectionIndeces.slice(-1)[0] || 0;
    if (this.visibleSectionIndeces.length) {
      currentProgress = currentProgress / this.props.training.sections.length;
    }
    currentProgress = currentProgress * 100;

    let parentClasses = "";
    if (this.props.previewMode) {
      parentClasses += "pointer-events-none";
    }

    return (
      <div className={parentClasses}>
        <div
          className="bg-white border-b border-light-grey sticky top-0 z-30"
          id="t-progress"
        >
          <div className="mx-auto max-w-4xl py-5 x-padded">
            <div className="bg-light-grey w-full rounded-md">
              <div
                className="bg-primary-grey-blue py-1 text-center rounded-md"
                style={{ width: `${currentProgress}%` }}
              />
            </div>
            <div className="table table-fixed w-full pt-2 relative">
              {this.props.training.sections.map((section, idx) => (
                <div key={`t-progress-${idx}`} className="table-cell">
                  <span className="h-6 w-6 text-center rounded-full bg-primary-grey-blue mr-1 text-white font-bold inline-block">
                    {idx + 1}
                  </span>
                  <span className="font-bold hidden md:inline">
                    {section.name}
                  </span>
                </div>
              ))}
            </div>
            {!this.props.config.user && !this.props.previewMode ? (
              <div className="progress-signup-cta">
                <a
                  href={JsRoutes.new_user_registration_path()}
                  className="button-primary mt-6 auth-control-signup"
                  style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                >
                  Sign Up Now
                </a>
              </div>
            ) : null}
          </div>
        </div>
        <div className="bg-white">
          {visibleSections.map((section, idx) => [
            visibleSections.length > 1 && idx > 0 ? (
              <hr
                key={`ts-hr-${section.section.id}`}
                className="border-t-4 border-light-grey-01"
              />
            ) : null,
            <TrainingSection
              config={this.props.config}
              section={section.section}
              sectionIndex={idx}
              training={this.props.training}
              trainingComponents={section.components}
              trainingProgress={this.props.trainingProgress}
              hasNext={idx < this.props.training.sections.length - 1}
              onNext={this.handleNext}
              key={`ts-${section.section.id}`}
              previewMode={this.props.previewMode}
            />,
            idx == this.props.training.sections.length - 1 &&
            !this.props.config.user ? (
              <div
                key="ts-call-to-action"
                className="text-center max-w-xl2 mx-auto container border-t-4 border-light-grey-01 py-10"
              >
                <p className="font-bold">
                  Ready to learn and practice more Teach Like a Champion
                  techniques?
                </p>
                <div>
                  <a
                    href={JsRoutes.new_user_registration_path()}
                    className="button-primary mt-6 auth-control-signup"
                  >
                    Sign Up Now
                  </a>
                </div>
              </div>
            ) : null,
          ])}
        </div>
      </div>
    );
  }
}

TrainingComponents.propTypes = {
  training: PropTypes.object,
  trainingComponents: PropTypes.arrayOf(PropTypes.object),
  config: PropTypes.object,
  previewMode: PropTypes.bool,
};

export default TrainingComponents;
