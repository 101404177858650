import React from "react";
import PropTypes from "prop-types";
import Markdown from "./Markdown";

const Text = ({ trainingComponent }) => (
  <Markdown source={trainingComponent.data.text} />
);

Text.propTypes = {
  trainingComponent: PropTypes.object,
};

export default Text;
