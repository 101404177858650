import React, { useState } from "react";
import JsRoutes from "../util/routes";
import getMetaValue from "../util/getMetaValue";

const Login = ({ onChangeAuthType }) => {
  const [loginForm, updateLoginForm] = useState({
    email: "",
    password: "",
    remember_me: true,
  });
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    updateLoginForm({
      ...loginForm,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(JsRoutes.user_session_path(), {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": getMetaValue("csrf-token"),
      },
      method: "POST",
      body: JSON.stringify({
        user: loginForm,
      }),
    });

    const responseData = await response.json();

    if (response.ok) {
      const newLocation =
        response.headers.get("Location") || JsRoutes.root_path();
      window.location.href = newLocation;
    } else {
      setError(responseData.error);
    }
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    onChangeAuthType("signup");
  };

  const errorMessage = error ? <p className="error-message">{error}</p> : null;

  return (
    <div className="px-3-5rem pb-12 text-primary-grey-blue max-w-4xl">
      <h1 className="leading-none text-primary-grey-blue-02 mb-12">
        Welcome back!
      </h1>
      <form
        onSubmit={handleSubmit}
        className="form-inputs p-10 bg-white white-bg-inputs"
      >
        <div className="input">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            className="w-3/4"
            name="email"
            type="email"
            placeholder="Your e-mail address"
            onChange={handleChange}
            value={loginForm.email}
            required={true}
          />
        </div>
        <div className="input">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            className="w-3/4"
            name="password"
            type="password"
            placeholder="Your password"
            onChange={handleChange}
            value={loginForm.password}
            required={true}
          />
        </div>
        {errorMessage}
        <button type="submit" className="button-primary mt-6">
          Log In
        </button>
        <div className="text-sm mt-6">
          <p className="mb-4">
            <a href={JsRoutes.new_user_password_path()} className="link">
              Forgot your password?
            </a>
          </p>
          <p className="mb-4">
            Don't have an account?{" "}
            <a
              href={JsRoutes.new_user_registration_path()}
              onClick={handleSignUp}
              className="link"
            >
              Sign Up
            </a>
          </p>
          <p>
            <a href={JsRoutes.new_user_confirmation_path()} className="link">
              Didn't receive your confirmation instructions?
            </a>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;
