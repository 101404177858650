import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "throttle-debounce";
import gaTrackEvent from "../../gaTrackEvent";
import extractEmails from "../../util/extractEmails";
import JsRoutes from "../../util/routes";

const AddTeachers = (props) => {
  const [usersLeft, setUsersLeft] = useState(props.usersLeft);
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState(null);

  const updateEmails = useCallback(
    debounce(1000, (inputEmails) => {
      if (!inputEmails) {
        setError(null);
        setUsersLeft(props.usersLeft);
        return;
      }

      const emailExtraction = extractEmails(inputEmails);

      if (emailExtraction.errors.length) {
        setError(
          `Please check that the following addresses are correct: ${emailExtraction.errors.join(
            ", "
          )}`
        );
        setEmails([]);
        setUsersLeft(props.usersLeft);
        return;
      }

      if (emailExtraction.emails.join(",") != emails.join(",")) {
        const newUsersLeft = props.usersLeft - emailExtraction.emails.length;
        if (newUsersLeft < 0) {
          setError("Your group has reached its maximum number of users.");
          return;
        }

        setError(null);
        setEmails(emailExtraction.emails);
        setUsersLeft(newUsersLeft);
      }
    }),
    []
  );

  const onChangeEmails = (e) => updateEmails(e.target.value);

  const pickFile = () => {
    gaTrackEvent("sign-up", "add-teachers-choose-file", "manager");
  };

  const submit = () => {
    gaTrackEvent("sign-up", "add-teachers-4", "manager");
  };

  const skip = () => {
    setEmails([]);
    gaTrackEvent("sign-up", "add-teachers-4-skip", "manager");
  };

  return (
    <div className="form-inputs white-bg-inputs mt-6">
      <div>
        <div>
          <strong>1. Import users from a file</strong>
        </div>
        <p className="my-4">
          Upload a CSV file with the users to be imported.&nbsp;
          <a className="link" href={"/setup_users_sample.csv"}>
            See an example file.
          </a>
        </p>
        <div className="input">
          <input
            type="file"
            name="users_import"
            accept=".csv"
            className="mr-4"
            onClick={pickFile}
          ></input>
          <button className="button-form" type="submit">
            Import
          </button>
        </div>
      </div>
      <div className="mt-10">
        <div>
          <strong>2. Add users by e-mail address</strong>
        </div>
        <p className="my-4">
          Add one or more comma-separated e-mail addresses.
        </p>
        <div className="input">
          <textarea name="users" className="w-2/3" onChange={onChangeEmails} />
        </div>
        {error ? <p className="error-message">{error}</p> : null}
        <p>
          <strong>{usersLeft}</strong> users left
        </p>
        <div className="hidden">
          {emails.map((email, idx) => (
            <input
              type="hidden"
              name="emails[]"
              value={email}
              key={`email-${idx}`}
            />
          ))}
        </div>
      </div>
      <div className="flex mt-6">
        <button
          className="button-form"
          type="submit"
          disabled={emails.length == 0}
          onClick={submit}
        >
          Add users
        </button>
        <button className="button-link" type="submit" onClick={skip}>
          I'll add more users later
        </button>
      </div>
    </div>
  );
};

export default AddTeachers;
