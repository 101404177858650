import React, { useState } from "react";
import Modal from "./Modal";
import PurchaseAccounts from "./PurchaseAccounts";
import JsRoutes from "../util/routes";

const PurchaseMoreAccountsModal = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen || false);

  document.addEventListener("click", (e) => {
    const target = e.target;
    if (target.classList.contains("renew-control-open")) {
      setIsOpen(true);
    }
  });

  const closeModal = () => setIsOpen(false);

  const handleSuccess = (data, _response) => {
    setIsOpen(false);
    localStorage.setItem("recentlyPurchasedAccounts", data.newAccounts);
    window.location.href = JsRoutes.new_invitation_path();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <PurchaseAccounts
        numAccounts={props.numAccounts}
        startDate={props.startDate}
        endDate={props.endDate}
        groupName={props.groupName}
        onCancel={closeModal}
        subscriptionPlans={props.subscriptionPlans}
        onSuccess={handleSuccess}
        fromDashboard={true}
        title={`Add new accounts to ${props.groupName}`}
      />
    </Modal>
  );
};

export default PurchaseMoreAccountsModal;
